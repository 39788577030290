import React from "react";
import { deleteDocument } from "../../../../../apis/deleteDocument";
import "./DeleteDocuments.scss";
import { toast } from "react-toastify";

export interface TextProps {
  handleCloseDeleteModal: any;
  deleteID: any;
  deleteLoading: any;
  setDeleteLoading: any;
}

const DeleteSearchDocumentES: React.FC<TextProps> = ({
  handleCloseDeleteModal,
  deleteID,
  deleteLoading,
  setDeleteLoading,
}) => {
  const onDeleteDocument = () => {
    setDeleteLoading(true);
    deleteDocument(deleteID)
      .then((resp) => {
        toast.success("Documento eliminado con éxito");
        setDeleteLoading(false);
        handleCloseDeleteModal();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al eliminar documentos. Inténtalo de nuevo.");
        handleCloseDeleteModal();
        setDeleteLoading(false);
      });
  };
  return (
    <div className="delete-document-container">
      <h3>¿Estás seguro de eliminar ?</h3>
      <div className="delete-buttons">
        <button onClick={onDeleteDocument}>Sí</button>
        <button onClick={handleCloseDeleteModal}>Cancelar</button>
      </div>
    </div>
  );
};

export default DeleteSearchDocumentES;
