import axios, { AxiosError } from 'axios';


const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
    baseURL: baseURL
}); 

export const updateDocument = async (upload: any) => {

  let token = localStorage.getItem('token');
  try {
    let formData = new FormData();
    formData.append('name', upload.name);
    formData.append('type', upload.type);
    formData.append('law_number', upload.law_number);
    formData.append('year', upload.year);
    formData.append('date', upload.date);
    formData.append('status', upload.status);
    if (upload.file) {
      formData.append('file', upload.file);
      formData.append('format', 'pdf');
    }
    formData.append('regulation_number', upload.regulation_number);
    formData.append('agency', upload.agency);
    formData.append('municipality_number', upload.municipality_number);
    formData.append('municipality', upload.municipality);
    formData.append('is_indexed', upload.is_indexed);
    formData.append('is_titled', upload.is_titled);
    formData.append('is_completed', upload.is_completed);

    let result = await api.patch(`documents/${upload.id}/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response) {
        console.log('Error response:', axiosError.response.data);
        console.log('Error status:', axiosError.response.status);
        console.log('Error headers:', axiosError.response.headers);
      }
    }
    throw error;
  }
};
