import React, { useState } from "react";
import "./AdminSettingSetup.scss";
import DownloadedDocuments from "./Documentsdetails/DownloadedDocuments";
import DocumentsIndexing from "./Documentsdetails/DocumentsIndexing";
import UploadedDocuments from "./Documentsdetails/UploadedDocuments";
import { FiDownload, FiUpload } from "react-icons/fi";
import { MdOutlineDocumentScanner } from "react-icons/md";
import DocumentTypes from "./DocumentsTypes/DocumentTypes";
import DocumentAnalysis from "./DocumentAnalysis/DocumentAnalysis";
import SearchAdminDocuments from "./SearchAdminDocuments/SearchAdminDocuments";
import Modal from "../../../ReuseableComponents/Modal/Modal";
import UploadCSV from "./SyncFile/UploadCSV";

type Props = {};

const AdminSettingSetup = (Props: Props) => {
  const [page, setPage] = useState(0);
  const [isRadio, setIsRadio] = useState(1);
  const [fileModal, setFileModal] = useState(false);
  const handleOpenFileModal = () => {
    setFileModal(true);
  };

  const handleCloseFileModal = () => {
    setFileModal(false);
  };
  const componentList = [
    <DownloadedDocuments isRadio={isRadio} />,
    <DocumentsIndexing isRadio={isRadio} />,
    <UploadedDocuments isRadio={isRadio} />,
  ];
  return (
    <>
      <div className="admin-setting-container">
        <div className="documents-types">
          <button
            className={`documents-type-button ${page === 0 ? "active" : ""}`}
            onClick={() => setPage(0)}
          >
            Downloaded Documents
            <FiDownload className="icon" />
          </button>
          <button
            className={`documents-type-button ${page === 1 ? "active" : ""}`}
            onClick={() => setPage(1)}
          >
            Documents Indexing
            <MdOutlineDocumentScanner className="icon" />
          </button>
          <button
            className={`documents-type-button ${page === 2 ? "active" : ""}`}
            onClick={() => setPage(2)}
          >
            Uploaded Documents
            <FiUpload className="icon" />
          </button>
        </div>
        <div className="document-types-radio">
          <DocumentTypes isRadio={isRadio} setIsRadio={setIsRadio} />
        </div>
        <div className="Documents-detail-container">{componentList[page]}</div>
      </div>
      <SearchAdminDocuments />
      <DocumentAnalysis />
      <div className="sync-container">
        <button className="sync-button" onClick={handleOpenFileModal}>
          Sync Records
        </button>
      </div>
      <div className="col-md-3">
        {fileModal && (
          <Modal
            title="Upload CSV File"
            content={<UploadCSV handleCloseFileModal={handleCloseFileModal} />}
            onClose={handleCloseFileModal}
          />
        )}
      </div>
    </>
  );
};

export default AdminSettingSetup;
