import React, { useState, useEffect } from "react";
import FooterES from "./FooterES";
import HeaderES from "./Header/HeaderES";
import HeroSectionES from "./HeroSectionES";
import "./DESetup.scss";
import PopularLawsES from "./PopularLawsES";
import HistoricalLawsES from "./HistoricalLawsES";
import JointResolutionLawsES from "./JointResolutionLawsES";
import LatestLawsES from "./LatestLawsES";
import { getSignUpUserDetail } from "../../../apis/getSignupUserDetail";
import { getLatestLaws } from "../../../apis/getLatestLaws";
import { getPopularAndHistory } from "../../../apis/getPopularAndHistory";
import { getTemplate } from "../../../apis/getTemplate";
import { postAnalysis } from "../../../apis/postAnalysis";
import DownloadList from "../../ReuseableComponents/DownloadList/DownloadList";
import DownloadListES from "../../ReuseableComponents/DownloadList/DownloadListES";
import PageLoader from "../../ReuseableComponents/Loader/PageLoader";

type Props = {};

const DEESSetup = (props: Props) => {
  let userID = localStorage.getItem("user_id");
  const [isStaff, setIsStaff] = useState<any>();
  const [latestLaw, setLatestLaws] = useState<any>();
  const [latestJoint, setLatestJoints] = useState<any>();
  const [popularLaw, setPopularLaws] = useState<any>();
  const [historyrLaw, setHistoryLaws] = useState<any>();
  const [webTemplate, setWebTemplate] = useState<any>();
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState("");
  const [cursor, setCursor] = useState("");
  const [demandLoading, setDemandLoading] = useState(false);

  const [disabledButtons, setDisabledButtons] = useState<any>([]);
  const handleButtonClick = (items: any) => {
    if (disabledButtons.length < 3) {
      setDisabledButtons(
        (prevDisabledButtons: { doc_id: string; documentName: string }[]) => [
          ...prevDisabledButtons,
          { doc_id: items.doc_id, documentName: items.name },
        ]
      );
    } else {
      setError("Rango Excedido");
    }
  };

  useEffect(() => {
    if (disabledButtons.length < 3) {
      setError("");
      setCursor("");
    } else {
      setCursor("not-allowed");
    }
  }, [disabledButtons]);

  const handleRemove = (itemToRemove: any) => {
    const updatedButtons = disabledButtons.filter(
      (item: any) => item !== itemToRemove
    );
    setDisabledButtons(updatedButtons);
  };

  useEffect(() => {
    const homePageHit = {
      home: true,
      search: false,
    };
    postAnalysis(homePageHit)
      .then((userData) => {
        console.log(userData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSignUpUserDetail(userID)
      .then((userData) => {
        setIsStaff(userData?.is_staff);
      })
      .catch((err) => {
        console.log(err);
      });
    getLatestLaws()
      .then((L) => {
        setLatestLaws(L?.latest_laws);
        setLatestJoints(L?.joint_resolutions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getPopularAndHistory()
      .then((PH) => {
        setPopularLaws(PH?.popular_documents);
        setHistoryLaws(PH?.historical_documents);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    getTemplate()
      .then((Template) => {
        setWebTemplate(Template);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isStaff, loading]);
  return (
    <>
      <HeaderES />
      <div className="DE-Container">
        <HeroSectionES
          isStaff={isStaff}
          webTemplate={webTemplate}
          loading={loading}
          setLoading={setLoading}
        />
        <LatestLawsES
          isStaff={isStaff}
          latestLaw={latestLaw}
          loading={loading}
          webTemplate={webTemplate}
          setLoading={setLoading}
          handleButtonClick={handleButtonClick}
          disabledButtons={disabledButtons}
          cursor={cursor}
        />
        <JointResolutionLawsES
          isStaff={isStaff}
          latestJoint={latestJoint}
          loading={loading}
          webTemplate={webTemplate}
          setLoading={setLoading}
          handleButtonClick={handleButtonClick}
          disabledButtons={disabledButtons}
          cursor={cursor}
        />
        <PopularLawsES
          isStaff={isStaff}
          popularLaw={popularLaw}
          loading={loading}
          webTemplate={webTemplate}
          setLoading={setLoading}
          handleButtonClick={handleButtonClick}
          disabledButtons={disabledButtons}
          cursor={cursor}
        />
        <HistoricalLawsES
          isStaff={isStaff}
          historyrLaw={historyrLaw}
          loading={loading}
          webTemplate={webTemplate}
          setLoading={setLoading}
          handleButtonClick={handleButtonClick}
          disabledButtons={disabledButtons}
          cursor={cursor}
        />
        <FooterES />
      </div>
      <DownloadListES
        disabledButtons={disabledButtons}
        setDisabledButtons={setDisabledButtons}
        handleRemove={handleRemove}
        error={error}
        demandLoading={demandLoading}
        setDemandLoading={setDemandLoading}
      />
      {demandLoading ? <PageLoader /> : ""}
    </>
  );
};

export default DEESSetup;
