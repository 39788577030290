import React, { useState } from "react";
import "./EditActions.scss";
import { bulk } from "../../../../../apis/bulk";
import { toast } from "react-toastify";

export interface TextProps {
  handleCloseEditActionsModal: any;
  selectedCheckboxes: any;
  setBulkLoading: any;
}

const EditActionsES: React.FC<TextProps> = ({
  handleCloseEditActionsModal,
  selectedCheckboxes,
  setBulkLoading
}) => {
  const [isHide, setIsHide] = useState("False");
  const [isTitled, setIsTitled] = useState("False");
  const [isIndexed, setIsIndexed] = useState("False");
  const [isCompleted, setIsCompleted] = useState("False");

  
  const handleHide = () => {
    if (isHide === "False"){
    setIsHide("True");
    }
    else {
      setIsHide("False");
    }
  };  
  const handleCompleted = () => {
    if (isCompleted === "False"){
    setIsCompleted("True");
    }
    else {
      setIsCompleted("False");
    }
  };  
  const handleIndexed = () => {
    if (isIndexed === "False"){
    setIsIndexed("True");
    }
    else {
      setIsIndexed("False");
    }
  };  
  const handleTitled = () => {
    if (isTitled === "False"){
    setIsTitled("True");
    }
    else {
      setIsTitled("False");
    }
  };
  const onUpdateBulk = () => {
    setBulkLoading(true)
    const bulkData = {
      ids: selectedCheckboxes,
      is_hidden: isHide,
      is_indexed: isIndexed,
      is_titled: isTitled,
      is_completed: isCompleted
    };
    bulk(bulkData)
      .then((response) => {
        toast.success("Acciones Actualizadas con éxito");
        handleCloseEditActionsModal();
        setBulkLoading(false);
        
      })
      .catch((error) => {
        toast.error("Error de Actualización de Acciones. Inténtalo de nuevo.");
      });
  };


  return (
    <div className="edit-document-container">
      <h3>¿Está seguro de cambiar el estado de todos los documentos seleccionados?</h3>
      <div className="actions">
        <div className="action">
          <p className="title">Índice</p>
          <input className="checkbox" type="checkbox" onClick={handleIndexed}/>
        </div>
        <div className="action">
          <p className="title">Título</p>
          <input className="checkbox" type="checkbox" onClick={handleTitled}/>
        </div>
        <div className="action">
          <p className="title">Terminada</p>
          <input className="checkbox" type="checkbox" onClick={handleCompleted}/>
        </div>
        <div className="action">
          <p className="title">Oculta</p>
          <input className="checkbox" type="checkbox" onClick={handleHide}/>
        </div>
      </div>
      <div className="edit-buttons">
        <button onClick={onUpdateBulk}>Yes</button>
        <button onClick={handleCloseEditActionsModal}>Cancel</button>
      </div>
    </div>
  );
};

export default EditActionsES;
