import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetail } from "../../../../../apis/userApis/getUserDetail";
import { updateUser } from "../../../../../apis/userApis/updateUser";

type Props = {};
interface UserFormProps {
  handleCloseModalEdit: any;
  editUserID: any;
}

const UpdateUserES = ({ handleCloseModalEdit, editUserID }: UserFormProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [update, setUpdate] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    group: "",
  });

  const { data } = useQuery(["users"], () => getUserDetail(editUserID));
  useEffect(() => {
    setUpdate((prev) => {
      return {
        ...prev,
        first_name: data?.first_name ?? "",
        last_name: data?.last_name ?? "",
        email: data?.email ?? "",
        password: data?.password ?? "",
        group: data?.group ?? "",
      };
    });
  }, [data]);

  const { mutate } = useMutation(updateUser, {
    onSuccess: (data) => {
      toast.success("Usuario actualizado con éxito");
      handleCloseModalEdit();
    },
    onError: (error: any) => {
      let key = Object.keys(error.response.data)[0];
      toast.error(`${key} should not be empty`);
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });
  const updateHandler = () => {
    let user = {
      ...update,
    };
    if (editUserID) {
      mutate({ id: editUserID, user: user });
    }
  };
  const changeHandler = (e: any) => {
    const target = e.target;
    const name = target.name;
    setUpdate((prev) => {
      return {
        ...prev,
        [name]: target.value,
      };
    });
  };
  return (
        <div className='user-form'>
          <FormGroup className="form-field">
            <Label htmlFor="first_name">Nombre de pila</Label>
            <Input
              className="input-field"
              type="text"
              id="first_name"
              name="first_name"
              value={update.first_name}
              onChange={changeHandler}
            />
          </FormGroup>
          <FormGroup className="form-field">
            <Label htmlFor="first_name">Apellido</Label>
            <Input
              className="input-field"
              type="text"
              id="last_name"
              name="last_name"
              value={update.last_name}
              onChange={changeHandler}
            />
          </FormGroup>
          <FormGroup className="form-field">
            <Label htmlFor="first_name">Correo Electrónico</Label>
            <Input
              className="input-field"
              type="text"
              id="email"
              name="email"
              value={update.email}
              onChange={changeHandler}
            />
          </FormGroup>
          {/* <FormGroup className="form-field">
            <Label htmlFor="first_name">Contraseña</Label>
            <Input
              className="input-field"
              type="text"
              id="password"
              name="password"
              value={update.password}
              onChange={changeHandler}
            />
          </FormGroup> */}
          <FormGroup className="form-field">
            <Label htmlFor="group">Grupo</Label>
            <select
              className="input-field"
              id="group"
              name="group"
              value={update.group}
              onChange={changeHandler}
            >
              <option value="option1">Grupo 1</option>
              <option value="option2">Grupo 2</option>
              <option value="option3">Grupo 3</option>
              <option value="option3">Grupo 4</option>
            </select>
          </FormGroup>
          <div className="form-buttons">
            <button className='confirm-btn' type="submit" onClick={updateHandler}>Actualizar</button>
            <button className='cancel-btn' onClick={handleCloseModalEdit}>Cancelar</button>
          </div>
        </div>
  );
};

export default UpdateUserES;
