import "./DESetup.scss";
import civil from "../../../assests/images/civil.png";
import panel from "../../../assests/images/panel.png";
import electoral from "../../../assests/images/electoral.png";
import lpau from "../../../assests/images/lpau.png";
import { Link } from "react-router-dom";
import { FiEdit, FiDownload } from "react-icons/fi";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Modal from "../../ReuseableComponents/Modal/Modal";
import DeleteLawDocumentES from "./SearchDocument/DeleteDocuments/DeleteLawDocumentES";
import { useState } from "react";
import Loader from "../../ReuseableComponents/Loader/Loader";
import { MdEdit } from "react-icons/md";
import PopularTextES from "./Text/PopularTextES";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

export interface StaffProps {
  isStaff: any;
  popularLaw: any;
  loading: any;
  setLoading: any;
  webTemplate: any;
  handleButtonClick: any;
  disabledButtons: any;
  cursor: any;
}

const PopularLawsES = ({
  isStaff,
  popularLaw,
  loading,
  setLoading,
  webTemplate,
  handleButtonClick,
  disabledButtons,
  cursor,
}: StaffProps) => {
  let img: any;
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [showTextModal, setShowTextModal] = useState(false);
  const [note, setNote] = useState<any>();
  const [docView, setDocView] = useState(false);
  const [onDemandData, setOnDemandData] = useState<any>();
  const [demandLoading, setDemandLoading] = useState(false);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleOpenTextModal = () => {
    setShowTextModal(true);
    setNote(webTemplate?.popular_laws_body_es);
  };

  const handleCloseTextModal = () => {
    setShowTextModal(false);
  };
  return (
    <div className="basic-laws-container">
      <div className="headings">
        <div className="topline">
          <h1>Leyes más populares</h1>
          <Link
            to="/upload-documents"
            style={{ display: isStaff ? "flex" : "none" }}
          >
            <button className="add-new">Agregar Nueva</button>
          </Link>
        </div>
        <div className="topline">
          <p>
            {webTemplate?.popular_laws_body_es}{" "}
            <MdEdit
              style={{ display: isStaff ? "inline-block" : "none" }}
              className="edit-icon"
              onClick={handleOpenTextModal}
            />
          </p>
          {docView ? (
            <div className="togle">
              <h4 className="toggle-title">RED</h4>
              <BsToggleOff
                className="toggle-icon"
                onClick={() => setDocView(false)}
              />
            </div>
          ) : (
            <div className="togle">
              <h4 className="toggle-title">CARRUSEL</h4>
              <BsToggleOn
                className="toggle-icon"
                onClick={() => setDocView(true)}
              />
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <>
          <Loader width={330} />
          <h4
            style={{
              textAlign: "center",
              color: "#083052",
              fontSize: "16px",
              margin: 0,
            }}
          >
            Buscando las Leyes Más Populares
          </h4>
        </>
      ) : (
        <>
          {docView ? (
            <div className="basic-laws">
              {popularLaw?.map((items: any) => {
                if (items?.id === 10702) {
                  img = lpau;
                } else if (items?.id === 10701) {
                  img = panel;
                } else if (items?.id === 10700) {
                  img = electoral;
                } else {
                  img = civil;
                }
                return (
                  <div className="basic-laws-card" key={items?.id}>
                    <img src={img} alt="popular" />
                    <h3>{items?.name.slice(0, 90)}</h3>
                    <div
                      className="card-buttons"
                      style={{ display: isStaff ? "flex" : "none" }}
                    >
                      {items?.file !== null ? (
                        <a
                          href={items?.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AiOutlineEye /> / <FiDownload />
                        </a>
                      ) : (
                        <>
                          {demandLoading && onDemandData.id === items.id ? (
                            <div>
                              <Loader width={60} />
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontSize: "12px",
                                }}
                              >
                                Descargando
                              </p>
                            </div>
                          ) : (
                            <button
                              style={{ cursor: cursor }}
                              className="download-button"
                              key={items.doc_id}
                              onClick={() => handleButtonClick(items)}
                              disabled={disabledButtons.some(
                                (button: any) =>
                                  button.doc_id === items.doc_id
                              )}
                            >
                              <AiOutlineEye /> / <FiDownload />
                            </button>
                          )}
                        </>
                      )}
                      <Link to={`/update-documents-en?id=${items.id}`}>
                        <FiEdit />
                      </Link>
                      <button
                        className="delete-button"
                        onClick={() => {
                          handleOpenDeleteModal();
                          setDeleteID(items?.id);
                        }}
                      >
                        <AiOutlineDelete />
                      </button>
                    </div>
                    <div
                      className="card-buttons"
                      style={{ display: isStaff ? "none" : "flex" }}
                    >
                      {items?.file !== null ? (
                        <a
                          href={items?.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-download"
                        >
                          Vista / Descargar
                        </a>
                      ) : (
                        <>
                          {demandLoading && onDemandData.id === items.id ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <Loader width="" />
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontSize: "12px",
                                  margin: 0,
                                }}
                              >
                                Descargando
                              </p>
                            </div>
                          ) : (
                            <button
                              style={{ cursor: cursor }}
                              className="download-button"
                              key={items.doc_id}
                              onClick={() => handleButtonClick(items)}
                              disabled={disabledButtons.some(
                                (button: any) =>
                                  button.doc_id === items.doc_id
                              )}
                            >
                              Vista / Descargar
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                1200: {
                  slidesPerView: 4,
                },
                990: {
                  slidesPerView: 3,
                },
                600: {
                  slidesPerView: 2,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              <div className="basic-laws">
                {popularLaw?.map((items: any) => {
                  if (items?.id === 10702) {
                    img = lpau;
                  } else if (items?.id === 10701) {
                    img = panel;
                  } else if (items?.id === 10700) {
                    img = electoral;
                  } else {
                    img = civil;
                  }
                  return (
                    <SwiperSlide>
                      <div className="basic-laws-card" key={items?.id}>
                        <img src={img} alt="popular" />
                        <h3>{items?.name.slice(0, 90)}</h3>
                        <div
                          className="card-buttons"
                          style={{ display: isStaff ? "flex" : "none" }}
                        >
                          {items?.file !== null ? (
                            <a
                              href={items?.file}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <AiOutlineEye /> / <FiDownload />
                            </a>
                          ) : (
                            <>
                              {demandLoading && onDemandData.id === items.id ? (
                                <div>
                                  <Loader width={60} />
                                  <p
                                    style={{
                                      textAlign: "center",
                                      color: "#fff",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Descargando
                                  </p>
                                </div>
                              ) : (
                                <button
                                  style={{ cursor: cursor }}
                                  className="download-button"
                                  key={items.doc_id}
                                  onClick={() => handleButtonClick(items)}
                                  disabled={disabledButtons.some(
                                    (button: any) =>
                                      button.doc_id === items.doc_id
                                  )}
                                >
                                  <AiOutlineEye /> / <FiDownload />
                                </button>
                              )}
                            </>
                          )}
                          <Link to={`/update-documents-en?id=${items.id}`}>
                            <FiEdit />
                          </Link>
                          <button
                            className="delete-button"
                            onClick={() => {
                              handleOpenDeleteModal();
                              setDeleteID(items?.id);
                            }}
                          >
                            <AiOutlineDelete />
                          </button>
                        </div>
                        <div
                          className="card-buttons"
                          style={{ display: isStaff ? "none" : "flex" }}
                        >
                          {items?.file !== null ? (
                            <a
                              href={items?.file}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="view-download"
                            >
                              Vista / Descargar
                            </a>
                          ) : (
                            <>
                              {demandLoading && onDemandData.id === items.id ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Loader width="" />
                                  <p
                                    style={{
                                      textAlign: "center",
                                      color: "#fff",
                                      fontSize: "12px",
                                      margin: 0,
                                    }}
                                  >
                                    Descargando
                                  </p>
                                </div>
                              ) : (
                                <button
                                  style={{ cursor: cursor }}
                                  className="download-button"
                                  key={items.doc_id}
                                  onClick={() => handleButtonClick(items)}
                                  disabled={disabledButtons.some(
                                    (button: any) =>
                                      button.doc_id === items.doc_id
                                  )}
                                >
                                  Vista / Descargar
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>
          )}
        </>
      )}

      <div className="col-md-3">
        {showDeleteModal && (
          <Modal
            title="Eliminar Documento"
            content={
              <DeleteLawDocumentES
                handleCloseDeleteModal={handleCloseDeleteModal}
                deleteID={deleteID}
              />
            }
            onClose={handleCloseDeleteModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {showTextModal && (
          <Modal
            title="Actualizar Texto"
            content={
              <PopularTextES
                note={note}
                setNote={setNote}
                handleCloseTextModal={handleCloseTextModal}
                loading={loading}
                setLoading={setLoading}
              />
            }
            onClose={handleCloseTextModal}
          />
        )}
      </div>
    </div>
  );
};

export default PopularLawsES;
