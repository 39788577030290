import React from "react";
import { deleteDocument } from "../../../../../apis/deleteDocument";
import "./DeleteDocuments.scss";
import { toast } from "react-toastify";
import { hideDocument } from "../../../../../apis/HideDocument";

export interface TextProps {
  handleCloseHideModal: any;
  docID: any;
  setIsHideLoading: any;
}

const HideDocumentES: React.FC<TextProps> = ({
  handleCloseHideModal,
  docID,
  setIsHideLoading,
}) => {
  const onHideDocument = () => {
    setIsHideLoading(true)
    const update = {
      id: docID,
      is_hidden: true,
    };
    hideDocument(update)
      .then((resp) => {
        setIsHideLoading(false)
        handleCloseHideModal();
        toast.success("Ocultar Documento Correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al Ocultar Documentos. Inténtalo de Nuevo.");
        handleCloseHideModal();
      });
  };
  return (
    <div className="delete-document-container">
      <h3>¿Estás segura de ocultar este documento?</h3>
      <div className="delete-buttons">
        <button onClick={onHideDocument}>Sí</button>
        <button onClick={handleCloseHideModal}>Cancelar</button>
      </div>
    </div>
  );
};

export default HideDocumentES;
