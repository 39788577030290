import React, { useState } from "react";
import "./AdminSettingSetup.scss";
import { FiDownload, FiUpload } from "react-icons/fi";
import { MdOutlineDocumentScanner } from "react-icons/md";
import DownloadedDocumentsES from "./DocumentsdetailsES/DownloadedDocumentsES";
import DocumentsIndexingES from "./DocumentsdetailsES/DocumentsIndexingES";
import UploadedDocumentsES from "./DocumentsdetailsES/UploadedDocumentsES";
import DocumentTypesES from "./DocumentsTypes/DocumentTypesES";
import DocumentAnalysisES from "./DocumentAnalysis/DocumentAnalysisES";
import SearchAdminDocumentsES from "./SearchAdminDocuments/SearchAdminDocumentsES";
import Modal from "../../../ReuseableComponents/Modal/Modal";
import UploadCSVES from "./SyncFile/UploadCSVES";

type Props = {};

const AdminSettingSetupES = (Props: Props) => {
  const [page, setPage] = useState(0);
  const [isRadio, setIsRadio] = useState(1);
  const [fileModal, setFileModal] = useState(false);

  const handleOpenFileModal = () => {
    setFileModal(true);
  };

  const handleCloseFileModal = () => {
    setFileModal(false);
  };
  
  const componentList = [
    <DownloadedDocumentsES isRadio={isRadio} />,
    <DocumentsIndexingES isRadio={isRadio} />,
    <UploadedDocumentsES isRadio={isRadio} />,
  ];

  return (
        <>
          <div className="admin-setting-container">
            <div className="documents-types">
              <button
                className={`documents-type-button ${
                  page === 0 ? "active" : ""
                }`}
                onClick={() => setPage(0)}
              >
                Documentos Descargados
                <FiDownload className="icon" />
              </button>
              <button
                className={`documents-type-button ${
                  page === 1 ? "active" : ""
                }`}
                onClick={() => setPage(1)}
              >
                Indexación de Documentos
                <MdOutlineDocumentScanner className="icon" />
              </button>
              <button
                className={`documents-type-button ${
                  page === 2 ? "active" : ""
                }`}
                onClick={() => setPage(2)}
              >
                Documentos Cargados
                <FiUpload className="icon" />
              </button>
            </div>
            <div className="document-types-radio">
              <DocumentTypesES isRadio={isRadio} setIsRadio={setIsRadio} />
            </div>
            <div className="Documents-detail-container">
              {componentList[page]}
            </div>
          </div>
          <SearchAdminDocumentsES />
          <DocumentAnalysisES />
          <div className="sync-container">
            <button className="sync-button" onClick={handleOpenFileModal}>
              Sincronizar Registros
            </button>
          </div>
          <div className="col-md-3">
            {fileModal && (
              <Modal
                title="Cargar Archivo CSV"
                content={
                  <UploadCSVES handleCloseFileModal={handleCloseFileModal} />
                }
                onClose={handleCloseFileModal}
              />
            )}
          </div>
    </>
  );
};

export default AdminSettingSetupES;
