import React, { useState } from "react";
import "./EditActions.scss";
import { bulk } from "../../../../../apis/bulk";
import { toast } from "react-toastify";

export interface TextProps {
  handleCloseEditActionsModal: any; 
  selectedCheckboxes: any;
  setBulkLoading: any;
}

const EditActions: React.FC<TextProps> = ({
  handleCloseEditActionsModal,
  selectedCheckboxes,
  setBulkLoading
}) => {
  const [isHide, setIsHide] = useState("False");
  const [isTitled, setIsTitled] = useState("False");
  const [isIndexed, setIsIndexed] = useState("False");
  const [isCompleted, setIsCompleted] = useState("False");

  
  const handleHide = () => {
    if (isHide === "False"){
    setIsHide("True");
    }
    else {
      setIsHide("False");
    }
  };  
  const handleCompleted = () => {
    if (isCompleted === "False"){
    setIsCompleted("True");
    }
    else {
      setIsCompleted("False");
    }
  };  
  const handleIndexed = () => {
    if (isIndexed === "False"){
    setIsIndexed("True");
    }
    else {
      setIsIndexed("False");
    }
  };  
  const handleTitled = () => {
    if (isTitled === "False"){
    setIsTitled("True");
    }
    else {
      setIsTitled("False");
    }
  };
  
  const onUpdateBulk = () => {
    setBulkLoading(true)
    const bulkData = {
      ids: selectedCheckboxes,
      is_hidden: isHide,
      is_indexed: isIndexed,
      is_titled: isTitled,
      is_completed: isCompleted
    };
    bulk(bulkData)
      .then((response) => {
        toast.success("Updated Actions Successfully");
        handleCloseEditActionsModal();
        setBulkLoading(false);
        
      })
      .catch((error) => {
        toast.error("Actions Updating Error. Please Try Again.");
      });
  };


  return (
    <div className="edit-document-container">
      <h3>Are you sure to Change Status of all selected Documents.?</h3>
      <div className="actions">
        <div className="action">
          <p className="title">Index</p>
          <input className="checkbox" type="checkbox" onClick={handleIndexed}/>
        </div>
        <div className="action">
          <p className="title">Title</p>
          <input className="checkbox" type="checkbox" onClick={handleTitled}/>
        </div>
        <div className="action">
          <p className="title">Completed</p>
          <input className="checkbox" type="checkbox" onClick={handleCompleted}/>
        </div>
        <div className="action">
          <p className="title">Hidden</p>
          <input className="checkbox" type="checkbox" onClick={handleHide}/>
        </div>
      </div>
      <div className="edit-buttons">
        <button onClick={onUpdateBulk}>Yes</button>
        <button onClick={handleCloseEditActionsModal}>Cancel</button>
      </div>
    </div>
  );
};

export default EditActions;
