import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface ChartData {
  name: string;
  value: number;
}

interface BarChartProps {
  data: ChartData[];
}

const DocumentsDetailChart: React.FC<BarChartProps> = ({ data }) => {
  return (
    <div className="rechart-container">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" className="dataKey-name" />
          <YAxis dataKey="hits" />
          <Tooltip />
          <Legend
            content={
              <div className="legend">
                <div className="box"></div>Year's
              </div>
            }
          />
          <Bar dataKey="hits" fill="#1869b3" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DocumentsDetailChart;
