import React, { useEffect, useState } from 'react';
import BackupRecordsTable from './BackupRecordsTable';
import Loader from '../../../../ReuseableComponents/Loader/Loader';
import { getLogging } from '../../../../../apis/getLogging';
import { postLogging } from '../../../../../apis/postLogging';

const BackupRecords = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggingData, setLoggingData] = useState();

  const handleBackup = () => {
    setLoading(true);
  
    postLogging()
      .then((postLoggingResp) => {
        console.log("postLogging", postLoggingResp);
  
        // Execute getLogging after postLogging is completed
        return getLogging();
      })
      .then((getLoggingResp) => {
        setLoggingData(getLoggingResp);
        setLoading(false);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  

  return (
    <div className="database-logs">
      <button className="logs-button" onClick={handleBackup}>
        Backup Database
      </button>
      {loading ? (
        <>
          <Loader width={330} />
          <h4 style={{ textAlign: 'center', color: '#083052', fontSize: '16px', margin: 0 }}>
            Searching for Records
          </h4>
        </>
      ) : (
        <>
        {show ? <BackupRecordsTable loggingData={loggingData}/> : ""}
        </>
      )}
    </div>
  );
};

export default BackupRecords;
