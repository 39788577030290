import { useEffect, useState } from "react";
import { getAnalysisData } from "../../../../../apis/getAnalysisData";
import "./DocumentAnalysis.scss";

const DocumentAnalysisES = () => {
  const [homePageVisits, setHomePageVisits] = useState();
  const [searchPageVisits, setSearchPageVisits] = useState();
  const [searchHits, setSearchHits] = useState(); 
  const [loginHits, setLoginHits] = useState(); 

  useEffect(() => { 
    getAnalysisData() 
      .then((userData) => {
        setHomePageVisits(userData?.home_page);
        setSearchPageVisits(userData?.search_page);
        setSearchHits(userData?.search_hits);
        setLoginHits(userData?.number_of_logins);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="document-analysis-container">
      <div className="user-data">
        <h4>Visitas a la Página de Inicio</h4>
        <h1>{homePageVisits}</h1>
      </div>
      <div className="user-data">
        <h4>Visitas a la Página de Búsqueda</h4>
        <h1>{searchPageVisits}</h1>
      </div>
      <div className="user-data">
        <h4>Resultados de Búsqueda</h4>
        <h1>{searchHits}</h1>
      </div>
      <div className="user-data">
        <h4>Número de Inicios de Sesión</h4>
        <h1>{loginHits}</h1>
      </div>
    </div>
  );
};

export default DocumentAnalysisES;
