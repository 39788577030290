import "./AIAnalysis.scss";

export interface StaffProps {
  insightDetails: any;
}

const AIAnalysis = ({ insightDetails }: StaffProps) => {
  return (
    <div className="ai-analysis-container">
      <div className="ai-analysis-distribution">
        <h2 className="title">Insight Documents</h2>
        <div className="ai-analysis-card-main">
          <h3 className="heading">Total Documents</h3>
          <h3 className="heading">{insightDetails?.total_documents}</h3>
        </div>
      </div>
      <div className="ai-analysis-distribution">
        <h2 className="title">Documents Distribution by Format</h2>
        <div className="ai-analysis-cards">
          {insightDetails?.document_format_distribution?.map(
            (items: any, index: number) => {
              return (
                <div className="ai-analysis-card" key={index}>
                  <h3 className="heading">
                    {items?.format === "" ? "Others" : items?.format}
                  </h3>
                  <h3 className="heading">{items?.count}</h3>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div className="ai-analysis-distribution">
        <h2 className="title">Documents Distribution by Type</h2>
        <div className="ai-analysis-cards">
          {insightDetails?.document_type_distribution?.map(
            (items: any, index: number) => {
              return (
                <div className="ai-analysis-card" key={index}>
                  <h3 className="heading">{items?.type}</h3>
                  <h3 className="heading">{items?.count}</h3>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default AIAnalysis;
