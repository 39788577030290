import { Checkbox } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getAllPermissions } from "../../../../../../apis/groupApis/getAllPermissions";
import { createGroup } from "../../../../../../apis/groupApis/createGroup";
import { getGroupData } from "../../../../../../apis/groupApis/getGroup";
import { updateUserGroup } from "../../../../../../apis/groupApis/updateGroup";
import "../groupsSetup.scss";

export interface ModalFormProps {
  modal: boolean;
  toggle: any;
  userClickData: any;
  setModal: (value: boolean) => void;
  setshowModal: (value: boolean) => void;
  setUserClickData: (value: any) => void;
}

export interface PermissionProps {
  id: number | undefined;
  codename: string;
}

export interface EditMetaProps {
  id?: number;
  name: string;
  permissions: any;
}

const GroupFormES = ({
  modal,
  toggle,
  userClickData,
  setModal,
  setshowModal,
}: ModalFormProps) => {
  const queryClient = useQueryClient();

  const { data: allPermissionsMeta } = useQuery(
    ["getAllPermissions"],
    getAllPermissions
  );
  const [groupTitle, setGroupTile] = useState("Create");
  const [disbaled, setDisbaled] = useState(true);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [editApiMetaData, setEditApiMeta] = useState<EditMetaProps>({
    id: 0,
    name: "",
    permissions: [],
  });

  const { handleSubmit, control, setValue } = useForm();

  const { mutate: createUserGroup } = useMutation(createGroup, {
    onSuccess: (data) => {
      toast.success("Se crea el grupo");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  const uniquePermissionsName = useMemo(
    () =>
      Array.from(
        new Set(
          allPermissionsMeta?.map((item: any) => item.codename.split("_")[1])
        )
      ),
    [allPermissionsMeta]
  );

  const onChangeCheckbox = (id: any, e: any) => {
    let updatedList =
      userClickData.type === "edit"
        ? [...selectedPermissions, ...editApiMetaData?.permissions]
        : [...selectedPermissions];
    if (e.target.checked) {
      updatedList = [...updatedList, id];
    } else {
      updatedList = updatedList.filter((item) => item !== id);
    }
    updatedList = updatedList.filter(function (item, pos) {
      return updatedList.indexOf(item) === pos;
    });
    setSelectedPermissions(updatedList);
    const permissions = editApiMetaData?.permissions?.filter(
      (item: any) => item !== id
    );
    setEditApiMeta(({ id, name }) => {
      return { id, name, permissions };
    });
  };

  const onCreateGroup = (data: any) => {
    const groupBody: any = {
      name: data?.name,
      level: "leve_text",
      permissions: selectedPermissions,
    };
    createUserGroup(groupBody);
    setSelectedPermissions([]);
    setModal(false);
  };

  const onUpdateGroup = (data: any) => {
    const groupBody: any = {
      name: data?.name,
      level: "leve_text",
      permissions: selectedPermissions,
    };
    updateUserGroup(groupBody, editApiMetaData?.id)
      .then(() => {
        toast.success("La información del grupo está actualizada.");
      })
      .catch(() => {
        toast.error("El grupo no está actualizado.");
      });
    setModal(false);
  };

  useEffect(() => {
    if (userClickData.type === "edit") {
      getGroupData(userClickData?.id)
        .then((resp) => {
          setValue("name", resp?.name);
          setSelectedPermissions(resp?.permissions)
          setshowModal(false);
          setEditApiMeta({
            id: resp?.id,
            name: resp?.name,
            permissions: resp?.permissions,
          });
        })
        .catch((err) => {
          setshowModal(false);
        });
      setModal(true);
    }
    else{
      setValue("name", '')
    }
    return () => {
      setEditApiMeta({
        id: 0,
        name: "",
        permissions: [],
      });
    };
  }, [userClickData]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="group-modal">
        <div className="modal-topbar">
          <h2 className="title-heading">{userClickData.type === "edit" ? "Actualizar": "Crear"} Grupo</h2>
          <button className="close-button" onClick={toggle}>X</button>
        </div>
        <ModalBody>
          <div className="modal-header-style">
            <div>
              <form onSubmit={handleSubmit(onCreateGroup)}>
                <Controller
                  name="name"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        aria-label="input-field"
                        value={value}
                        id={value}
                        autoComplete="off"
                        type="text"
                        name="name"
                        placeholder="Nombre del Grupo"
                        className="input-txt"
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </form>
            </div>
            <div className="permission-span">
              <span>Permisos</span>
              <span>Crear</span>
              <span>Update</span>
              <span>Actualizar</span>
              <span>Leer</span>
            </div>
            <div className="main-div">
              <div className="main-body">
                <div className="permission-title">
                  {uniquePermissionsName?.map((item: any, index: number) => {
                    return (
                      <div className="inner-content" key={index}>
                        <span>{item}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="permission-checkbox">
                  {allPermissionsMeta?.map((permission: any, index: number) => {
                    return (
                      <div key={index}>
                        <CHECKBOX
                          id={permission?.id}
                          permissions={editApiMetaData}
                          handleChange={onChangeCheckbox}
                          selectedPermissions={selectedPermissions}
                          type={userClickData.type}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Button
            className={classNames({
              "next-btn": true,
              isDisbaled: disbaled,
            })}
            onClick={handleSubmit(
              userClickData?.type ? onUpdateGroup : onCreateGroup
            )}
          >
            {userClickData?.type ? "Grupo de Actualización" : "Crea un Grupo"}
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default GroupFormES;

const CHECKBOX = (props: any) => {
  const { permissions, id, handleChange, type } = props;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (permissions?.permissions?.includes(id) && type === "edit") {
      setChecked(true);
    }
  }, [permissions]);

  return (
    <Checkbox
      checked={checked}
      onChange={(e) => {
        setChecked((prev) => !prev);
        handleChange(id, e);
      }}
    />
  );
};
