import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { signup } from '../../../../../apis/signup';
import { toast } from 'react-toastify';
interface UserFormProps {
  handleCloseCreateUserForm: any;
}
interface UserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  group: string;
  profession: any;
  motive: any;
}

const initialValues: UserFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  group: '',
  profession: "nene",
  motive: "none",
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('Se requiere el primer nombre'),
  lastName: Yup.string().required('El apellido es obligatorio'),
  email: Yup.string().email('Dirección de correo electrónico no válida').required('Correo Electronico es requerido'),
  password: Yup.string().required('Se requiere contraseña'),
  group: Yup.string().required('Se requiere grupo'),
});

const CreateNewUserES = ({ handleCloseCreateUserForm }: UserFormProps) => {
    const countries = ['Grupo 1', 'Grupo 2', 'Grupo 3', 'Grupo 4'];
      
const onSubmit = (values: UserFormValues) => {
  signup(values)
  .then(() => {
    toast.success("Nueva usuario creada con éxito");
    handleCloseCreateUserForm();
  })
  .catch((err) => {
    if (err) toast.error(err?.response?.data?.error);
  });
};
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className='user-form'>
          <div className="form-field">
            <label htmlFor="firstName">Nombre de Pila</label>
            <Field className="input-field" type="text" id="firstName" name="firstName" />
            <ErrorMessage name="firstName" component="div" />
          </div>

          <div className="form-field">
            <label htmlFor="lastName">Apellido</label>
            <Field className="input-field" type="text" id="lastName" name="lastName" />
            <ErrorMessage name="lastName" component="div" />
          </div>

          <div className="form-field">
            <label htmlFor="email">Correo Electrónico</label>
            <Field className="input-field" type="email" id="email" name="email" />
            <ErrorMessage name="email" component="div" />
          </div>
          
          <div className="form-field">
            <label htmlFor="password">Contraseña</label>
            <Field className="input-field" type="password" id="password" name="password" />
            <ErrorMessage name="password" component="div" />
          </div>

          <div className="form-field">
            <label htmlFor="group">Grupo</label>
            <Field className="input-field" as="select" id="group" name="group">
              <option value="" disabled>Selecciona Grupo</option>
              {countries.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </Field>
            <ErrorMessage name="group" component="div" />
          </div>

            <button className='form-button' type="submit">Crear</button>
        </Form>
      </Formik>
    </div>
  );
};

export default CreateNewUserES;
