import React, { useEffect, useState } from "react";
import { getAdminSearchDocuments } from "../../../../../apis/getAdminSearchDocuments";
import { getAdminSearchDocumentsHidden } from "../../../../../apis/getAdminSearchDocumentsHidden";
import "./SearchAdminDocuments.scss";
import file from "../../../../../assests/images/downloadPDF.png";
import doc from "../../../../../assests/images/doc.jpeg";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import PagingNation from "../../SearchDocument/PagingnationSearchDocuments";
import Modal from "../../../../ReuseableComponents/Modal/Modal";
import UnHideDocument from "../../SearchDocument/IsHideDocument/unHideDocument";
import HideDocument from "../../SearchDocument/IsHideDocument/hideDocument";
import DeleteSearchDocument from "../../SearchDocument/DeleteDocuments/DeleteSearchDocument";
import Loader from "../../../../ReuseableComponents/Loader/Loader";
import EditActions from "../../SearchDocument/EditActions/EditActions";

type Props = {};

export interface search {
  name: string;
  law_number: any;
  type: any;
  year: any;
  status: any;
  keywords: string;
  regulation_number: any;
  agency: any;
  municipality_number: any;
  municipality: any;
  page: any;
}

const SearchAdminDocuments = (props: Props) => {
  let userName: string | null = localStorage.getItem("username");
  let Name: string = userName ? userName.slice(0, 2) : '';
  const bottomRef = React.useRef<HTMLDivElement>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [generalFields, setGeneralFields] = useState(true);
  const [agenciesFields, setAgenciesFields] = useState(false);
  const [municipalFields, setMunicipalFields] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [deleteLoading, setDeleteLoading] = useState();
  const [isHideLoading, setIsHideLoading] = useState();
  const [unHideModal, setUnHideModal] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [docID, setDocID] = useState();
  const [activeButton, setActiveButton] = useState<string>("");
  const [adminSearchResults, setAdminSearchResults] = useState([]);
  const [adminSearchResponse, setAdminSearchResponse] = useState<any>();
  const [adminSearchLoading, setAdminSearchLoading] = useState(false);
  const [getTitleModal, setGetTitleModal] = useState(false);
  const [editActionsModal, setEditActionsModal] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]); 
  const [bulkLoading, setBulkLoading] = useState();

  const handlePageChange = (selectedPage: number) => {
    setPageNumber(selectedPage + 1);
  };

  const handleButtonClick = (buttonType: string) => {
    setAdminSearchResults([]);
    setLoadingButton(true);
    setPageNumber(1);
    setAdminSearchLoading(true);
    setActiveButton(buttonType);

    if (buttonType === "visible") {
      getAdminSearchDocuments(pageNumber)
        .then((resp) => {
          setAdminSearchResults(resp?.data);
          setAdminSearchResponse(resp);
          setAdminSearchLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getAdminSearchDocumentsHidden()
        .then((resp) => {
          setAdminSearchResults(resp?.data);
          setAdminSearchResponse(resp);
          setAdminSearchLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (activeButton === "visible") {
      setAdminSearchLoading(true);
      getAdminSearchDocuments(pageNumber)
        .then((resp) => {
          setAdminSearchResults(resp?.data);
          setAdminSearchResponse(resp);
          setAdminSearchLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (activeButton === "hidden") {
      setAdminSearchLoading(true);
      getAdminSearchDocumentsHidden()
        .then((resp) => {
          setAdminSearchResults(resp?.data);
          setAdminSearchResponse(resp);
          setAdminSearchLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [pageNumber, deleteLoading, isHideLoading, bulkLoading]);

  useEffect(() => {
    setPageNumber(1);
  }, [loadingButton]);

  const handleOpenHideModal = () => {
    setHideModal(true);
  };

  const handleCloseHideModal = () => {
    setHideModal(false);
  };

  const handleOpenUnHideModal = () => {
    setUnHideModal(true);
  };

  const handleCloseUnHideModal = () => {
    setUnHideModal(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  
  const handleOpenGetTitleModal = () => {
    setGetTitleModal(true);
  };
  const handleCloseGetTitleModal = () => {
    setGetTitleModal(false);
  };

  const handleOpenEditActionsModal = () => {
    setEditActionsModal(true);
  };
  const handleCloseEditActionsModal = () => {
    setEditActionsModal(false);
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedCheckboxes(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(item => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  return (
    <div className="search-admin-documents-container">
      <div className="search-admin-documents-buttons">
        <button
          className={`search-admin-documents-button ${
            activeButton === "visible" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("visible")}
        >
          Search <br /> Visible Documents
        </button>
        <button
          className={`search-admin-documents-button ${
            activeButton === "hidden" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("hidden")}
        >
          Search <br /> Hidden Documents
        </button>
      </div>

      {adminSearchLoading ? (
          <>
          <Loader width={330}/>
          <h4 style={{textAlign: 'center', color: '#083052', fontSize: '16px', margin: 0}}>Searching for Documents</h4>
          </>
      ) : (
        <div className="search-ref-data" ref={bottomRef}>
          {adminSearchResults?.length === 0 ? (
            <></>
          ) : (
            <div className="admin-results-topline">
              <h3>Document Results</h3>
              <div className="right-side">
                <h3 className="result-heading">Total Hits: {adminSearchResponse?.total_documents}</h3>
                <button className="action-button" onClick={handleOpenEditActionsModal}>Action <span>{selectedCheckboxes.length}</span></button>
              </div>
            </div>
          )}
          {adminSearchResults?.map((items: any) => {
            return (
              <div className="results">
                <div className="documents">
                  <div className="document">
                    <img
                      className="download-img"
                      src={file}
                      alt="file"
                      onClick={() => window.open(`${items?.file}`)}
                    />
                    <div className="pdf-file">
                      <div className="topline-action-container">
                        <h5 className="doc-id">
                          DOC ID:{" "}
                          <span>
                            {items?.doc_id !== "" ? items?.doc_id || "N/A" : ""}
                          </span>
                        </h5>
                        <div className="topline-actions">
                          <div className="action">
                            <button className="user-button">{Name}</button>
                            <p>
                              {items && items.modified
                                ? new Date(items.modified).toLocaleString(
                                    "en-US",
                                    { month: "short", day: "numeric" }
                                  )
                                : null}
                            </p>
                            <p>
                              {items && items.modified
                                ? new Date(items.modified).toLocaleTimeString(
                                    "en-US",
                                    {
                                      hour: "numeric",
                                      minute: "2-digit",
                                      hour12: true,
                                    }
                                  )
                                : null}
                            </p>
                          </div>
                          <div className="action">
                            <p>Index</p>
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={items?.is_indexed}
                            />
                          </div>
                          <div className="action">
                            <p>Title</p>
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={items?.is_titled}
                            />
                          </div>
                          <div className="action">
                            <p>Completed</p>
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={items?.is_completed}
                            />
                          </div>
                          <div className="action">
                            <BsEyeSlash
                              className="download-icon"
                              style={{
                                display: items?.is_hidden ? "flex" : "none",
                              }}
                              onClick={() => {
                                handleOpenUnHideModal();
                                setDocID(items?.id);
                              }}
                            />
                            <BsEye
                              className="download-icon"
                              style={{
                                display: items?.is_hidden ? "none" : "flex",
                              }}
                              onClick={() => {
                                handleOpenHideModal();
                                setDocID(items?.id);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="headline">
                        <div className="left-side">
                          <img src={doc} alt="doc" />
                          <h4
                            style={{
                              display: generalFields ? "flex" : "none",
                            }}
                          >
                            {items?.type}{" "}
                            {items?.law_number
                              ? items?.law_number === "nan"
                                ? "N/A"
                                : items?.law_number
                              : "N/A"}
                            -{items?.year}
                          </h4>
                          <h4
                            style={{
                              display: agenciesFields ? "flex" : "none",
                            }}
                          >
                            {items?.type}{" "}
                            {items?.regulation_number
                              ? items?.law_number === "nan"
                                ? "N/A"
                                : items?.regulation_number
                              : "N/A"}
                            -{items?.year}
                          </h4>
                          <h4
                            style={{
                              display: municipalFields ? "flex" : "none",
                            }}
                          >
                            {items?.type}{" "}
                            {items?.municipality_number
                              ? items?.law_number === "nan"
                                ? "N/A"
                                : items?.municipality_number
                              : "N/A"}
                            -{items?.year}
                          </h4>
                        </div>
                        <h4 className="center">
                          {items?.status
                            ? items?.status === "nan"
                              ? "N/A"
                              : items?.status
                            : "N/A"}
                        </h4>
                        <h4>
                          {items?.date
                            ? items?.date === "nan"
                              ? ""
                              : items?.date
                            : ""}
                        </h4>
                      </div>
                      <h4
                        className="category"
                        style={{
                          display: agenciesFields ? "flex" : "none",
                        }}
                      >
                        {items?.agency
                          ? items?.agency === "nan"
                            ? ""
                            : items?.agency
                          : ""}
                      </h4>
                      <h4
                        className="category"
                        style={{
                          display: municipalFields ? "flex" : "none",
                        }}
                      >
                        {items?.municipality
                          ? items?.municipality === "nan"
                            ? ""
                            : items?.municipality
                          : ""}
                      </h4>
                      {items?.name
                        ? items?.name === "nan"
                          ? ""
                          : items?.name
                        : ""}
                      <div className="action-icons">
                        <button className="action-button" onClick={handleOpenGetTitleModal}>Get Title</button>
                        <AiOutlineDelete
                          className="delete-icon"
                          onClick={() => {
                            handleOpenDeleteModal();
                            setDeleteID(items?.id);
                          }}
                        />
                        <Link
                          className="edit-icon"
                          to={`/update-documents-en?id=${items.id}&link=/admin-setting-en`}
                        >
                          <FiEdit />
                        </Link>
                      </div>
                    </div>
                      <input
                        type="checkbox"
                        className="select-checkbox"
                        onChange={() => handleCheckboxChange(items.id)}
                        checked={selectedCheckboxes.includes(items.id)}
                      />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {adminSearchResults?.length === 0 ? (
        <p></p>
      ) : (
        <PagingNation
          onPageChange={handlePageChange}
          pageCount={adminSearchResponse?.total_pages}
        />
      )}
      <div className="col-md-3">
        {showDeleteModal && (
          <Modal
            title="Delete Document"
            content={
              <DeleteSearchDocument
                handleCloseDeleteModal={handleCloseDeleteModal}
                deleteID={deleteID}
                deleteLoading={deleteLoading}
                setDeleteLoading={setDeleteLoading}
              />
            }
            onClose={handleCloseDeleteModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {hideModal && (
          <Modal
            title="Hide Document"
            content={
              <HideDocument
                docID={docID}
                handleCloseHideModal={handleCloseHideModal}
                setIsHideLoading={setIsHideLoading}
              />
            }
            onClose={handleCloseHideModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {unHideModal && (
          <Modal
            title="Unhide Document"
            content={
              <UnHideDocument
                docID={docID}
                handleCloseUnHideModal={handleCloseUnHideModal}
                setIsHideLoading={setIsHideLoading}
              />
            }
            onClose={handleCloseUnHideModal}
          />
        )}
      </div>
        <div className="col-md-3">
          {getTitleModal && (
            <Modal
              title="Get Title"
              content={"PDF FILE"}
              onClose={handleCloseGetTitleModal}
            />
          )}
        </div>
        <div className="col-md-3">
          {editActionsModal && (
            <Modal
              title="Edit Actions"
              content={
                <EditActions
                  handleCloseEditActionsModal={handleCloseEditActionsModal}
                  selectedCheckboxes={selectedCheckboxes}
                  setBulkLoading={setBulkLoading}
                />
              }
              onClose={handleCloseEditActionsModal}
            />
          )}
        </div>
    </div>
  );
};

export default SearchAdminDocuments;
