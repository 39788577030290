import React, { ChangeEvent, useEffect, useState } from "react";
import "./FormSelectorStyles.scss";
import { getYears } from "../../../../apis/getYears";

interface Option {
  value: string;
  label: string;
}

interface FormSelectorProps {
  // label: string;
  value: string;
  options: any;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  error?: string;
}

function YearSelectorES({ value, options, onChange, error }: FormSelectorProps) {
  const [years, setYears] = useState<any>([]); // Initialize with an empty array

  useEffect(() => {
    getYears(options)
      .then((userData) => {
        setYears(userData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [options]);

  return (
    <div className="custom-form-selector">
      {/* <label>{label}</label> */}
      <select value={value} onChange={onChange}>
        <option>
        Por Favor Seleccione Año
        </option>
        {years.length === 0 ? (
          <option disabled>Loading...</option>
        ) : (
          years.map((option: any) => (
            <option key={option?.year} value={option.value}>
              {option?.year} <span style={{color: 'red'}}>({option?.hits})</span>
            </option>
          ))
        )}
      </select>
      {error && <p>{error}</p>}
    </div>
  );
}

export default YearSelectorES;
