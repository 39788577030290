import {
  DeleteIcon,
  EditIcon,
  LockIcon,
} from "../../../../../assests/images/svgComponents";
import { ColumnType } from "../../../../ReuseableComponents/Table/Table.typs";
import { v4 as uuidv4 } from "uuid";

export const UserTableColumnsES: (
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onLock: (id: number) => void
) => ColumnType[] = (onDelete, onEdit, onLock) => [
  {
    name: "Nombre de Pila",
    dataKey: "first_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Apellido",
    dataKey: "last_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Correo Electrónico",
    dataKey: "email",
    align: "left",
    width: "10%",
  },
  {
    name: "Fecha de Incorporación",
    dataKey: "date_joined",
    align: "left",
    width: "10%",
    limit: 25,
    render: (cellData, rowData) => {
      return (
        <span>{(rowData?.date_joined?.slice(0, 10))}</span>
      );
    },
  },

  {
    name: "Comportamiento",
    dataKey: "actions",
    align: "right",
    width: "10%",
    limit: 25,
    render: (cellData, rowData) => {
      return (
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '5px', paddingRight: '10px'}}>
          <span
            className="status"
            onClick={() => {
              onEdit(rowData?.id);
            }}
          >
            <EditIcon />
          </span>
          <span
            className="status"
            onClick={() => {
              onDelete(rowData?.id);
            }}
          >
            <DeleteIcon />
          </span>
          <span
            className="status"
            onClick={() => {
              onLock(rowData?.id);
            }}
          >
            <LockIcon />
          </span>
        </div>
      );
    },
  },
];

export const UserDummyData = [
  {
    actions: uuidv4(),
    name: "Scan",
    email: "support@documentcontrol.com",
    time: "09:30 Am",
    user: "zohaib",
    lastLogin: "2022-07-06",
  },
  {
    actions: uuidv4(),
    name: "Scan",
    email: "support@documentcontrol.com",
    user: "zohaib",
    time: "09:30 Am",
    lastLogin: "2022-07-06",
  },
];
