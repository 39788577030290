import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
    baseURL: baseURL
}); 


export const getSearchDocuments = async (search: any) => {
    if (!search) {
        return null;
    }
    let searchType;
    if (search.type === 1) {
        searchType = "Ley";
    } else if (search.type === 2) {
        searchType = "RC";
    }else if (search.type === 3) {
        searchType = "Veto";
      } else if (search.type === 4) {
        searchType = "Reglamento de Agencias";
      }else if (search.type === 5) {
        searchType = "Reglamento Municipal";
      }else if (search.type === 6) {
        searchType = "Ordenanza Municipal";
      }else if (search.type === 7) {
        searchType = "Resolución Municipal";
      }else {
        searchType = "Ley";
      }
    let result = await api.get(
      `search_documents/?type=${searchType}&name=${search.name}&law_number=${search.law_number}&year=${search.year}&keywords=${search.keywords}&page=${search.page}&agency=${search.agency}&regulation_number=${search.regulation_number}&municipality=${search.municipality}&municipality_number=${search.municipality_number}`
    );    
    return result.data;
};

