import { useEffect, useState } from "react";
import CreateNewUser from "./CreateNewUser"
import "./UserManagement.scss"
import UserTable from "./UserTable"
import Modal from "../../../../ReuseableComponents/Modal/Modal";
import UserDesktop from "../user/UserDesktop";
import GroupSetup from "../Groups/GroupsSetup";
import { filterAdmins } from "../../../../../apis/filterAdmins";
import { getFilterUsers } from "../../../../../apis/getFilterUsers";
import DeletedUserDesktop from "../user/DeletedUserDesktop";

const UserManagement = () => {
  const [createUserForm, setCreateUserForm] = useState(false);
  const [usertype, setUsertype] = useState(false);
  const [filterUsers, setFilterUsers] = useState();
  const [filterUsersLoading, setFilterUsersLoading] = useState(false);
  const handleOpenCreateUserForm = () => {
    setCreateUserForm(true);
  };

  const handleCloseCreateUserForm = () => {
    setCreateUserForm(false);
  };

  const handleFilterAdmins = () => {
    setUsertype(true)
    setFilterUsersLoading(true)
    filterAdmins()
    .then((resp) => {
      setFilterUsers(resp)
      setFilterUsersLoading(false)
    })
    .catch((err) => {
      console.log(err)
    });
  };

  const handleFilterUsers = () => {
    setUsertype(false)
    setFilterUsersLoading(true)
    getFilterUsers()
    .then((resp) => {
      setFilterUsers(resp)
      setFilterUsersLoading(false)
    })
    .catch((err: any) => {
      console.log(err)
    });
  };

  useEffect(() => {
    handleFilterUsers()
  
    return () => {
      handleFilterUsers()
    }
  }, [])
  

  return (
    <div className="user-management-container">
      <div className="topbar">
        <h1 className="title">User Management</h1>
        <div className="filter-buttons">
          <button 
            className="filter-button" 
            style={{color: usertype ? '#fff' : 'green'}}
            onClick={handleFilterUsers}
          >
            Users
          </button>
          <button 
            className="filter-button" 
            style={{color: usertype ? 'green' : '#fff'}}
            onClick={handleFilterAdmins}
          >
            Admins
          </button>
        </div>
      </div>
      <div className="topbar">
        <h2 className="title-heading">{usertype ? 'Admins' : 'Users'}</h2>
        <button className="add-user-button" onClick={handleOpenCreateUserForm}>+New</button>
      </div>
      <UserDesktop filterUsers={filterUsers} filterUsersLoading={filterUsersLoading} usertype={usertype}/>
      <h1 className="title">Deleted Users</h1>
      <DeletedUserDesktop filterUsers={filterUsers} filterUsersLoading={filterUsersLoading} usertype={usertype}/>
      <h1 className="title">Group Management</h1>
      <GroupSetup />
      <div className="col-md-3">
        {createUserForm && (
          <Modal
            title="Create New User"
            content={<CreateNewUser handleCloseCreateUserForm={handleCloseCreateUserForm}/>}
            onClose={handleCloseCreateUserForm}
          />
        )}
      </div>
    </div>
  )
}

export default UserManagement