import { Routes, Route } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DE from "./pages/DE";
import SearchDocumentsDE from "./pages/SearchDocumentsDE";
import DEES from "./pages/DEES";
import SearchDocumentsES from "./components/desktop/DE/SearchDocument/SearchDocumentsES";
import UploadDocuments from "./components/desktop/DE/SearchDocument/UploadDocuments";
import UploadDocumentsES from "./components/desktop/DE/SearchDocument/UploadDocumentsES";
import AdminSetting from "./pages/AdminSetting";
import AdminSettingES from "./pages/AdminSettingES";
import UpdateDocumentsES from "./components/desktop/DE/SearchDocument/UpdateDocumentsES";
import UpdateDocuments from "./components/desktop/DE/SearchDocument/UpdateDocuments";
import UploadDocumentsAlertES from "./components/desktop/DE/SearchDocument/UploadDocumentsAlertES";
import UploadDocumentsAlert from "./components/desktop/DE/SearchDocument/UploadDocumentsAlert";
import UpdateDocumentsAlertES from "./components/desktop/DE/SearchDocument/UpdateDocumentsAlertES";
import UpdateDocumentsAlert from "./components/desktop/DE/SearchDocument/UpdateDocumentsAlert";
import SearchDocumentsPageES from "./pages/SearchDocumentsPageES";
import { useEffect } from "react";
import './App.css'

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    const tokenKey = "token";
    const expirationKey = "token_expiration";

    // Check if token exists in localStorage and if it's expired
    const storedToken = localStorage.getItem(tokenKey);
    const expirationTime = localStorage.getItem(expirationKey);

    if (storedToken && expirationTime) {
      const currentTime = new Date().getTime();
      if (parseInt(expirationTime) < currentTime) {
        // Token is expired, remove it from localStorage
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(expirationKey);
      }
    }

    const handleBeforeUnload = () => {
      // Set a timestamp indicating when the browser was closed
      const expirationTime = new Date().getTime() + 5000; // 5000 milliseconds (adjust as needed)
      localStorage.setItem(expirationKey, expirationTime.toString());
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<DEES />} />
        <Route path="/search-documents" element={<SearchDocumentsPageES />} />
        <Route path="/EN" element={<DE />} />
        <Route path="/search-documents-en" element={<SearchDocumentsDE />} />
        <Route path="/admin-setting" element={<AdminSettingES />} />
        <Route path="/admin-setting-en" element={<AdminSetting />} />
        <Route path="/upload-documents" element={<UploadDocumentsES />} />
        <Route path="/upload-documents-en" element={<UploadDocuments />} />
        <Route path="/update-documents" element={<UpdateDocumentsES />} />
        <Route path="/update-documents-en" element={<UpdateDocuments />} />
        {/* <Route path="/upload-documents" element={<UploadDocumentsAlertES />} />
        <Route path="/upload-documents-en" element={<UploadDocumentsAlert />} />
        <Route path="/update-documents" element={<UpdateDocumentsAlertES />} />
        <Route path="/update-documents-en" element={<UpdateDocumentsAlert />} /> */}
      </Routes>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
