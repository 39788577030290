import React, { ChangeEvent } from "react";
import "./HeroText.scss";
import { updateTemplate } from "../../../../apis/updateTemplate";
import { toast } from "react-toastify";

export interface TextProps {
  contact: string;
  setContact: React.Dispatch<React.SetStateAction<string>>;
  handleCloseContactModal: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeroContact: React.FC<TextProps> = ({
  contact,
  setContact,
  handleCloseContactModal,
  loading,
  setLoading,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newContact = event.target.value;
    setContact(newContact);
  };

  const onUpdateContact = () => {
    const update = {
      hero_section_contact_en: contact,
    };
    setLoading(true);
    updateTemplate(update)
      .then((response) => {
        toast.success("Contact Updated Successfully");
        handleCloseContactModal();
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Contact Updating Error. Please Try Again.");
      });
  };

  return (
    <div className="edit-text">
      <input
        type="text"
        value={contact}
        onChange={handleInputChange}
        className="edit-input"
      />
      <button className="edit-button" onClick={onUpdateContact}>
        {loading ? "Loading..." : "Update"}
      </button>
    </div>
  );
};

export default HeroContact;
