import React, { useEffect, useState } from "react";
import RadioButtonsGroup from "./RadioButtons";
import "./SearchDocuments.scss";
import { Input } from "reactstrap";
import file from "../../../../assests/images/downloadPDF.png";
import doc from "../../../../assests/images/doc.jpeg";
import { getSearchDocuments } from "../../../../apis/getSearchDocuments";
import { Controller, useForm } from "react-hook-form";
import Footer from "../Footer";
import UserProfile from "./UserProfile";
import FormSelector from "../FormSelector/FormSelector";
import SearchHeader from "../Header/SearchHeader";
import { agenciesOptions, municipalOptions, statusOptions } from "./Data/Data";
import PagingNation from "./PagingnationSearchDocuments";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getSignUpUserDetail } from "../../../../apis/getSignupUserDetail";
import Modal from "../../../ReuseableComponents/Modal/Modal";
import DeleteSearchDocument from "./DeleteDocuments/DeleteSearchDocument";
import Loader from "../../../ReuseableComponents/Loader/Loader";
import YearSelector from "../FormSelector/yearSelectByHits";
import UnHideDocument from "./IsHideDocument/unHideDocument";
import HideDocument from "./IsHideDocument/hideDocument";
import EditActions from "./EditActions/EditActions";
import TextFile from "./TextFile/TextFile";
import HyperlinkParagraph from "./SearchDocumentsHyperLink";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { toast } from "react-toastify";
import { generateThumbnail } from "../../../../apis/generateThumbnail";
import ReferencedDocuments from "./ReferencedDocuments/ReferencedDocuments";
export interface search {
  name: string;
  law_number: any;
  type: any;
  year: any;
  status: any;
  keywords: string;
  regulation_number: any;
  agency: any;
  municipality_number: any;
  municipality: any;
  page: any;
}
export interface DownloadProps {
  handleButtonClick: any;
  disabledButtons: any;
  cursor: any;
}

const SearchDocuments = ({
  handleButtonClick,
  disabledButtons,
  cursor,
}: DownloadProps) => {
  let token = localStorage.getItem("token");
  let userName: string | null = localStorage.getItem("username");
  let Name: string = userName ? userName.slice(0, 2) : "";
  let userID = localStorage.getItem("user_id");
  const bottomRef = React.useRef<HTMLDivElement>(null);

  const [isRadio, setIsRadio] = useState<any>(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchResult, setSearchResult] = useState<any>();
  const [isStaff, setIsStaff] = useState<any>();
  const [searchData, setSearch] = useState<any>();
  const [generalFields, setGeneralFields] = useState(true);
  const [agenciesFields, setAgenciesFields] = useState(false);
  const [municipalFields, setMunicipalFields] = useState(false);
  const [lawFields, setLawFields] = useState(true);
  const [resultPagingNation, setResultPagingNation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [deleteLoading, setDeleteLoading] = useState();
  const [isHideLoading, setIsHideLoading] = useState();
  const [unHideModal, setUnHideModal] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [getTitleModal, setGetTitleModal] = useState(false);
  const [editActionsModal, setEditActionsModal] = useState(false);
  const [docID, setDocID] = useState();
  const [onDemandData, setOnDemandData] = useState<any>();
  const [demandLoading, setDemandLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const [bulkLoading, setBulkLoading] = useState();
  const [notSelectedModal, setNotSelectedModal] = useState(false);
  const [textFile, setTextFile] = useState();
  const [hyperLinkDocument, setHyperLinkDocument] = useState<any>();
  const [thumbnailLoading, setThumbnailLoading] = useState<any>(false);
  const [afterLoading, setAfterLoading] = useState(true);
  const [isRefDocument, setIsRefDocument] = useState(false);


  const handleThumbnail = (id: any) => {
    setThumbnailLoading((prevLoadingState: any) => ({
      ...prevLoadingState,
      [id]: true,
    }));

    generateThumbnail(id)
    .then((resp) => {
      toast.success("Generated Thumbnail Successfully");
      setThumbnailLoading(false)
      setAfterLoading(!afterLoading)
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error Generating Thumbnail. Please try again.");
      setThumbnailLoading(false)
    });
    
  };

  const handleOpenHideModal = () => {
    setHideModal(true);
  };

  const handleCloseHideModal = () => {
    setHideModal(false);
  };

  const handleOpenUnHideModal = () => {
    setUnHideModal(true);
  };

  const handleCloseUnHideModal = () => {
    setUnHideModal(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleOpenGetTitleModal = (text_file: any) => {
    setGetTitleModal(true);
    setTextFile(text_file);
  };
  const handleCloseGetTitleModal = () => {
    setGetTitleModal(false);
  };
  const handleOpenEditActionsModal = () => {
    if (selectedCheckboxes.length > 0) {
      setEditActionsModal(true);
    } else {
      setNotSelectedModal(true);
    }
  };
  const handleCloseEditActionsModal = () => {
    setEditActionsModal(false);
  };
  const handleCloseNotSelectedModal = () => {
    setNotSelectedModal(false);
  };
  const scrollHandler = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setResultPagingNation(true);
    setLoadingButton(true);
    setHyperLinkDocument('');
    setSelectedCheckboxes([]);
    setIsRefDocument(false)
  };

  const [selectedYear, setSelectedYear] = useState("");
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
    setPageNumber(1);
    setSearchResult("");
    setLoadingButton(false);
  };

  const [selectedStatus, setSelectedStatus] = useState("");
  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
    setPageNumber(1);
    setSearchResult("");
    setLoadingButton(false);
  };

  const [selectedAgency, setSelectedAgency] = useState("");
  const handleAgencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgency(event.target.value);
    setPageNumber(1);
    setSearchResult("");
    setLoadingButton(false);
  };

  const [selectedMunicipal, setSelectedMunicipal] = useState("");
  const handleMunicipalChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMunicipal(event.target.value);
    setPageNumber(1);
    setSearchResult("");
    setLoadingButton(false);
  };

  const { handleSubmit, control } = useForm<search>({
    defaultValues: {
      name: "",
      law_number: "",
      type: isRadio,
      year: "",
      status: "",
      keywords: "",
      regulation_number: "",
      agency: "",
      municipality_number: "",
      municipality: "",
      page: pageNumber,
    },
    mode: "onChange",
    // resolver: yupResolver(UserInfoFormValidation),
  });
  const handlePageChange = (selectedPage: number) => {
    setPageNumber(selectedPage + 1);
  };
  const onSearchDocuments = (data: any) => {
    setLoading(true);
    const search = {
      type: isRadio,
      name: data?.name,
      law_number: data?.law_number,
      year: selectedYear.slice(0, 4),
      status: selectedStatus,
      keywords: data?.keywords,
      regulation_number: data?.regulation_number,
      agency: selectedAgency,
      municipality_number: data?.municipality_number,
      municipality: selectedMunicipal,
      page: pageNumber,
    };
    setSearch(search);
    getSearchDocuments(search)
      .then((resp) => {
        setSearchResult(resp);
        setLoading(false);
        setLoadingButton(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    onSearchDocuments(searchData);
    getSignUpUserDetail(userID)
      .then((userData) => {
        setIsStaff(userData?.is_staff);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageNumber, isStaff, deleteLoading, isHideLoading, bulkLoading, hyperLinkDocument, afterLoading]);

  useEffect(() => {
    setPageNumber(1);
    setSearchResult("");
    setLoadingButton(false);
  }, [isRadio]);

  if (!isRadio) {
    return null;
  }
  let searchType;
  let informationText: any;
  if (isRadio === 1) {
    searchType = "Law";
    informationText = "Ley";
  } else if (isRadio === 2) {
    searchType = "Joint Resolution";
    informationText = "RC";
  } else if (isRadio === 3) {
    searchType = "Veto";
    informationText = "vetos";
  } else if (isRadio === 4) {
    searchType = "Reglamento de Agencias";
    informationText = "Reglamento de Agencias";
  } else if (isRadio === 5) {
    searchType = "Reglamento Municipal";
    informationText = "Reglamento Municipal";
  } else if (isRadio === 6) {
    searchType = "Municipal Ordinance";
    informationText = "MO";
  } else if (isRadio === 7) {
    searchType = "Municipal Resolution";
    informationText = "M-Res";
  } else {
    searchType = "Law";
    informationText = "LEY";
  }

  const handleCheckboxChange = (id: number) => {
    setSelectedCheckboxes((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((item) => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  return (
    <div className="search-document-page">
      <SearchHeader />
      <div className="search-documents-container">
        <div className="search-document">
          <div className="form-container">
            <h1 className="page-title">Search of Legal Documents</h1>
            <div className="radio-buttons">
              <RadioButtonsGroup
                isRadio={isRadio}
                setIsRadio={setIsRadio}
                setGeneralFields={setGeneralFields}
                setAgenciesFields={setAgenciesFields}
                setMunicipalFields={setMunicipalFields}
                setLawFields={setLawFields}
              />
            </div>

            <form
              className="search-documents-form"
              onSubmit={handleSubmit(onSearchDocuments)}
            >
              <div
                className="filters"
                style={{ display: generalFields ? "flex" : "none" }}
              >
                <Controller
                  name="law_number"
                  control={control}
                  defaultValue="law_number"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        value={value}
                        error={error}
                        id="law_number"
                        type="number"
                        name="law_number"
                        placeholder="Number"
                        onChange={(e) => {
                          onChange(e);
                          setSearchResult("");
                          setPageNumber(1);
                          setLoadingButton(false);
                        }}
                        className="document-input"
                      />
                    );
                  }}
                />
                <YearSelector
                  value={selectedYear}
                  options={informationText}
                  onChange={handleYearChange}
                />
                <FormSelector
                  value={selectedStatus}
                  options={statusOptions}
                  onChange={handleStatusChange}
                />
              </div>
              <div
                className="filters"
                style={{ display: agenciesFields ? "flex" : "none" }}
              >
                <Controller
                  name="regulation_number"
                  control={control}
                  defaultValue="regulation_number"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        value={value}
                        error={error}
                        id="regulation_number"
                        type="number"
                        name="regulation_number"
                        placeholder="Regulation Number"
                        onChange={(e) => {
                          onChange(e);
                          setSearchResult("");
                          setPageNumber(1);
                          setLoadingButton(false);
                        }}
                        className="document-input"
                      />
                    );
                  }}
                />
                <FormSelector
                  value={selectedAgency}
                  options={agenciesOptions}
                  onChange={handleAgencyChange}
                />
                <FormSelector
                  value={selectedStatus}
                  options={statusOptions}
                  onChange={handleStatusChange}
                />
              </div>
              <div
                className="filters"
                style={{ display: agenciesFields ? "flex" : "none" }}
              >
                <YearSelector
                  value={selectedYear}
                  options={informationText}
                  onChange={handleYearChange}
                />
              </div>
              <div
                className="filters"
                style={{ display: municipalFields ? "flex" : "none" }}
              >
                <Controller
                  name="municipality_number"
                  control={control}
                  defaultValue="municipality_number"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        value={value}
                        error={error}
                        id="municipality_number"
                        type="number"
                        name="municipality_number"
                        placeholder="Municipality Number"
                        onChange={(e) => {
                          onChange(e);
                          setSearchResult("");
                          setPageNumber(1);
                          setLoadingButton(false);
                        }}
                        className="document-input"
                      />
                    );
                  }}
                />
                <FormSelector
                  value={selectedMunicipal}
                  options={municipalOptions}
                  onChange={handleMunicipalChange}
                />
                <FormSelector
                  value={selectedStatus}
                  options={statusOptions}
                  onChange={handleStatusChange}
                />
              </div>
              <div
                className="filters"
                style={{ display: municipalFields ? "flex" : "none" }}
              >
                <YearSelector
                  value={selectedYear}
                  options={informationText}
                  onChange={handleYearChange}
                />
              </div>
              <div className="search-bars">
                <h3 className="search-title">Search by:</h3>
                <Controller
                  name="name"
                  control={control}
                  defaultValue="name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        value={value}
                        error={error}
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Search by Name"
                        onChange={(e) => {
                          onChange(e);
                          setSearchResult("");
                          setPageNumber(1);
                          setLoadingButton(false);
                        }}
                        className="document-input"
                      />
                    );
                  }}
                />
                <Controller
                  name="keywords"
                  control={control}
                  defaultValue="keywords"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        value={value}
                        error={error}
                        id="keywords"
                        type="text"
                        name="keywords"
                        placeholder="Search by keywordss"
                        onChange={(e) => {
                          onChange(e);
                          setSearchResult("");
                          setPageNumber(1);
                          setLoadingButton(false);
                        }}
                        className="document-input"
                      />
                    );
                  }}
                />
              </div>
              <button
                className="document-button"
                type="submit"
                onClick={scrollHandler}
              >
                {loadingButton ? "Searching ..." : "Look For"}
              </button>
              <div className="note">
                <h6>
                  if they require a certified law, please go through the
                  Department of State every time there is a cost.
                </h6>
              </div>
              <div
                className="actions"
                style={{ display: isStaff ? "flex" : "none" }}
              >
                <Link className="staff-button" to="/en">
                  Edit Home Page
                </Link>
                <Link className="staff-button" to="/upload-documents-en">
                  Add New Documents
                </Link>
                <Link className="staff-button" to="/admin-setting-en">
                  Admin Analytics
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="search-ref-data" ref={bottomRef}>
          {loadingButton ? (
            <>
              <Loader width={330} />
              <h4 style={{ textAlign: "center", color: "#083052" }}>
                Searching for Documents
              </h4>
            </>
          ) : (
            <>
            {hyperLinkDocument ? (
            <>
            {/* hyper link document */}
                <div
                  className="documents-search-result"
                >
                  <h2 style={{color: '#083052'}}>Hyper Link Document</h2>
                      <div className="results">
                        {hyperLinkDocument.length === 0 ? (
                          <h3 className="no-documents-found">
                            No Documents Found
                          </h3>
                        ) : (
                          <>
                          <button className="back-button" onClick={() => setHyperLinkDocument(false)}><FaArrowAltCircleLeft /> Back</button>
                          <div className="topline">
                              <h3 className="result-heading">Document Results</h3>
                              <div className="right-side">
                                <button
                                  className="action-button"
                                  onClick={handleOpenEditActionsModal}
                                  style={{ display: isStaff ? "flex" : "none" }}
                                >
                                  Action <span>{selectedCheckboxes.length}</span>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    <>
                          <div className="results">
                            <div className="documents">
                              <div className="document">
                                {hyperLinkDocument?.file !== null ? (
                                  <button className="direct-download-button">
                                    <img
                                      className="download-img"
                                      src={file}
                                      alt="file"
                                      onClick={() =>
                                        window.open(`${hyperLinkDocument?.file}`)
                                      }
                                    />
                                  </button>
                                ) : (
                                  <>
                                    {demandLoading &&
                                    onDemandData.id === hyperLinkDocument?.id ? (
                                      <div>
                                        <Loader width={60} />
                                        <p
                                          style={{
                                            textAlign: "center",
                                            color: "#083052",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Downloading
                                        </p>
                                      </div>
                                    ) : (
                                      <button
                                        style={{ cursor: cursor }}
                                        className="download-button"
                                        key={hyperLinkDocument?.doc_id}
                                        onClick={() => handleButtonClick(hyperLinkDocument)}
                                        disabled={disabledButtons.some(
                                          (button: any) =>
                                            button.doc_id === hyperLinkDocument?.doc_id
                                        )}
                                      >
                                        <img
                                          className="download-img"
                                          src={file}
                                          alt="file"
                                        />
                                      </button>
                                    )}
                                  </>
                                )}
                                <div className="pdf-file">
                                  <div
                                    className="topline-action-container"
                                    style={{
                                      display: isStaff ? "flex" : "none",
                                    }}
                                  >
                                    <h5 className="doc-id">
                                      DOC ID:{" "}
                                      <span>
                                        {hyperLinkDocument?.doc_id !== ""
                                          ? hyperLinkDocument?.doc_id || "N/A"
                                          : ""}
                                      </span>
                                    </h5>
                                    <div
                                      className="topline-actions"
                                      style={{
                                        display: isStaff ? "flex" : "none",
                                      }}
                                    >
                                      <div className="action">
                                        <button className="user-button">
                                          {Name}
                                        </button>
                                        <p>
                                          {hyperLinkDocument && hyperLinkDocument?.modified
                                            ? new Date(
                                                hyperLinkDocument?.modified
                                              ).toLocaleString("en-US", {
                                                month: "short",
                                                day: "numeric",
                                              })
                                            : null}
                                        </p>
                                        <p>
                                          {hyperLinkDocument && hyperLinkDocument?.modified
                                            ? new Date(
                                                hyperLinkDocument?.modified
                                              ).toLocaleTimeString("en-US", {
                                                hour: "numeric",
                                                minute: "2-digit",
                                                hour12: true,
                                              })
                                            : null}
                                        </p>
                                      </div>
                                      <div className="action">
                                        <p>Index</p>
                                        <input
                                          className="checkbox"
                                          type="checkbox"
                                          checked={hyperLinkDocument?.is_indexed}
                                        />
                                      </div>
                                      <div className="action">
                                        <p>Title</p>
                                        <input
                                          className="checkbox"
                                          type="checkbox"
                                          checked={hyperLinkDocument?.is_titled}
                                        />
                                      </div>
                                      <div className="action">
                                        <p>Completed</p>
                                        <input
                                          className="checkbox"
                                          type="checkbox"
                                          checked={hyperLinkDocument?.is_completed}
                                        />
                                      </div>
                                      <div className="action">
                                        <BsEyeSlash
                                          className="download-icon"
                                          style={{
                                            display: hyperLinkDocument?.is_hidden
                                              ? "flex"
                                              : "none",
                                          }}
                                          onClick={() => {
                                            handleOpenUnHideModal();
                                            setDocID(hyperLinkDocument?.id);
                                          }}
                                        />
                                        <BsEye
                                          className="download-icon"
                                          style={{
                                            display: hyperLinkDocument?.is_hidden
                                              ? "none"
                                              : "flex",
                                          }}
                                          onClick={() => {
                                            handleOpenHideModal();
                                            setDocID(hyperLinkDocument?.id);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="headline">
                                    <div className="left-side">
                                      <img src={doc} alt="doc" />
                                      <h4
                                        style={{
                                          display: generalFields
                                            ? "flex"
                                            : "none",
                                        }}
                                      >
                                        {hyperLinkDocument?.type}{" "}
                                        {hyperLinkDocument?.law_number
                                          ? hyperLinkDocument?.law_number === "nan"
                                            ? "N/A"
                                            : hyperLinkDocument?.law_number
                                          : "N/A"}
                                        -{hyperLinkDocument?.year}
                                      </h4>
                                      <h4
                                        style={{
                                          display: agenciesFields
                                            ? "flex"
                                            : "none",
                                        }}
                                      >
                                        {hyperLinkDocument?.type}{" "}
                                        {hyperLinkDocument?.regulation_number
                                          ? hyperLinkDocument?.regulation_number
                                          : hyperLinkDocument?.law_number}
                                        -{hyperLinkDocument?.year}
                                      </h4>
                                      <h4
                                        style={{
                                          display: municipalFields
                                            ? "flex"
                                            : "none",
                                        }}
                                      >
                                        {hyperLinkDocument?.type}{" "}
                                        {hyperLinkDocument?.municipality_number
                                          ? hyperLinkDocument?.law_number === "nan"
                                            ? "N/A"
                                            : hyperLinkDocument?.municipality_number
                                          : "N/A"}
                                        -{hyperLinkDocument?.year}
                                      </h4>
                                    </div>
                                    <h4 className="center">
                                      {hyperLinkDocument?.status
                                        ? hyperLinkDocument?.status === "nan"
                                          ? "N/A"
                                          : hyperLinkDocument?.status
                                        : "N/A"}
                                    </h4>
                                    <h4>
                                      {hyperLinkDocument?.date
                                        ? hyperLinkDocument?.date === "nan"
                                          ? ""
                                          : hyperLinkDocument?.date
                                        : ""}
                                    </h4>
                                  </div>
                                  <h4
                                    className="category"
                                    style={{
                                      display: agenciesFields ? "flex" : "none",
                                    }}
                                  >
                                    {hyperLinkDocument?.agency
                                      ? hyperLinkDocument?.agency === "nan"
                                        ? ""
                                        : hyperLinkDocument?.agency
                                      : ""}
                                  </h4>
                                  <h4
                                    className="category"
                                    style={{
                                      display: municipalFields
                                        ? "flex"
                                        : "none",
                                    }}
                                  >
                                    {hyperLinkDocument?.municipality
                                      ? hyperLinkDocument?.municipality === "nan"
                                        ? ""
                                        : hyperLinkDocument?.municipality
                                      : ""}
                                  </h4>
                                  {/* {hyperLinkDocument?.name
                                    ? hyperLinkDocument?.name === "nan"
                                      ? ""
                                      : hyperLinkDocument?.name
                                    : ""} */}
                                    <HyperlinkParagraph 
                                     name={hyperLinkDocument?.name} 
                                     type={hyperLinkDocument?.type} 
                                     law_number={hyperLinkDocument?.law_number} 
                                     year={hyperLinkDocument?.year} 
                                     hyperLinkDocument={hyperLinkDocument}
                                     setHyperLinkDocument={setHyperLinkDocument}
                                     setLoadingButton={setLoadingButton}
                                    />
                                  <div
                                    className="action-icons"
                                    style={{
                                      display: isStaff ? "flex" : "none",
                                    }}
                                  >
                                    <button
                                      className="action-button"
                                      onClick={() =>
                                        handleOpenGetTitleModal(
                                          hyperLinkDocument?.file_content
                                        )
                                      }
                                    >
                                      Get Title 
                                    </button>
                                    <AiOutlineDelete
                                      className="delete-icon"
                                      onClick={() => {
                                        handleOpenDeleteModal();
                                        setDeleteID(hyperLinkDocument?.id);
                                      }}
                                    />
                                    <Link
                                      className="edit-icon"
                                      to={`/update-documents-en?id=${hyperLinkDocument?.id}&link=/search-documents-en`}
                                    >
                                      <FiEdit />
                                    </Link>
                                  </div>
                                </div>
                                <input
                                  style={{ display: isStaff ? "flex" : "none" }}
                                  type="checkbox"
                                  className="select-checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(hyperLinkDocument?.id)
                                  }
                                  checked={selectedCheckboxes.includes(
                                    hyperLinkDocument?.id
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                      </>
                </div>
              
            </>
            ) : (
            <>
              {searchResult?.data && (
                <div
                  className="documents-search-result"
                  style={{ display: resultPagingNation ? "flex" : "none" }}
                >
                  {loading ? (
                    <>
                      <Loader width={330} />
                      <h4
                        style={{
                          textAlign: "center",
                          color: "#083052",
                          fontSize: "16px",
                          margin: 0,
                        }}
                      >
                        Searching for Documents
                      </h4>
                    </>
                  ) : (
                    <>
                      <div className="search-data-tags">
                        <div className="tags-title">
                          Name: <span>{searchData?.name}</span>
                        </div>
                        <div className="tags-title">
                          Year: <span>{searchData?.year}</span>
                        </div>
                        <div
                          className="tags-title"
                          style={{ display: generalFields ? "flex" : "none" }}
                        >
                          Number: <span>{searchData?.law_number}</span>
                        </div>
                        <div
                          className="tags-title"
                          style={{ display: agenciesFields ? "flex" : "none" }}
                        >
                          Regulation Number:{" "}
                          <span>{searchData?.regulation_number}</span>
                        </div>
                        <div
                          className="tags-title"
                          style={{ display: agenciesFields ? "flex" : "none" }}
                        >
                          Agency: <span>{searchData?.agency}</span>
                        </div>
                        <div
                          className="tags-title"
                          style={{ display: municipalFields ? "flex" : "none" }}
                        >
                          Number: <span>{searchData?.municipality_number}</span>
                        </div>
                        <div
                          className="tags-title"
                          style={{ display: municipalFields ? "flex" : "none" }}
                        >
                          Municipality: <span>{searchData?.municipality}</span>
                        </div>

                        <div className="tags-title">
                          Type: <span>{searchType}</span>
                        </div>
                        <div className="tags-title">
                          Status: <span>{searchData?.status}</span>
                        </div>
                        <div className="tags-title">
                          keywords: <span>{searchData?.keywords}</span>
                        </div>
                      </div>
                              <div className="filter-buttons">
                                <button 
                                  className="filter-button" 
                                  style={{color: isRefDocument ? '#011d25' : '#fff'}}
                                  onClick={() => setIsRefDocument(false)}
                                >
                                  Documents Result
                                </button>
                                <button 
                                  className="filter-button" 
                                  style={{color: isRefDocument ? '#fff' : '#011d25'}}
                                  onClick={() => setIsRefDocument(true)}
                                >
                                  Referenced Documents
                                </button>
                              </div>
                    {isRefDocument ? (
                      <ReferencedDocuments />
                    ) : (
                      <>
                        <div className="results">
                        {searchResult?.data?.length === 0 ? (
                          <h3 className="no-documents-found">
                            No Documents Found
                          </h3>
                        ) : (
                          <div className="topline">
                            <h3 className="result-heading">Document Results</h3>
                            <div className="right-side">
                              <h3 className="result-heading">
                                Total Hits: {searchResult?.total_documents}
                              </h3>
                              <button
                                className="action-button"
                                onClick={handleOpenEditActionsModal}
                                style={{ display: isStaff ? "flex" : "none" }}
                              >
                                Action <span>{selectedCheckboxes.length}</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      {searchResult?.data?.map((items: any) => {
                        return (
                          <div className="results">
                            <div className="documents">
                              <div className="document">
                                {items?.file !== null ? (
                                  <button className="direct-download-button">
                                    <img
                                      className="download-img"
                                      src={file}
                                      alt="file"
                                      onClick={() =>
                                        window.open(`${items?.file}`)
                                      }
                                    />
                                  </button>
                                ) : (
                                  <>
                                    {demandLoading &&
                                    onDemandData.id === items.id ? (
                                      <div>
                                        <Loader width={60} />
                                        <p
                                          style={{
                                            textAlign: "center",
                                            color: "#083052",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Downloading
                                        </p>
                                      </div>
                                    ) : (
                                      <button
                                        style={{ cursor: cursor }}
                                        className="download-button"
                                        key={items.doc_id}
                                        onClick={() => handleButtonClick(items)}
                                        disabled={disabledButtons.some(
                                          (button: any) =>
                                            button.doc_id === items.doc_id
                                        )}
                                      >
                                        <img
                                          className="download-img"
                                          src={file}
                                          alt="file"
                                        />
                                      </button>
                                    )}
                                  </>
                                )}
                                <div className="pdf-file">
                                  <div
                                    className="topline-action-container"
                                    style={{
                                      display: isStaff ? "flex" : "none",
                                    }}
                                  >
                                    <h5 className="doc-id">
                                      DOC ID:{" "}
                                      <span>
                                        {items?.doc_id !== ""
                                          ? items?.doc_id || "N/A"
                                          : ""}
                                      </span>
                                    </h5>
                                    <div
                                      className="topline-actions"
                                      style={{
                                        display: isStaff ? "flex" : "none",
                                      }}
                                    >
                                      <div className="action">
                                        <button className="user-button">
                                          {Name}
                                        </button>
                                        <p>
                                          {items && items.modified
                                            ? new Date(
                                                items.modified
                                              ).toLocaleString("en-US", {
                                                month: "short",
                                                day: "numeric",
                                              })
                                            : null}
                                        </p>
                                        <p>
                                          {items && items.modified
                                            ? new Date(
                                                items.modified
                                              ).toLocaleTimeString("en-US", {
                                                hour: "numeric",
                                                minute: "2-digit",
                                                hour12: true,
                                              })
                                            : null}
                                        </p>
                                      </div>
                                      <div className="action">
                                        <p>Index</p>
                                        <input
                                          className="checkbox"
                                          type="checkbox"
                                          checked={items?.is_indexed}
                                        />
                                      </div>
                                      <div className="action">
                                        <p>Title</p>
                                        <input
                                          className="checkbox"
                                          type="checkbox"
                                          checked={items?.is_titled}
                                        />
                                      </div>
                                      <div className="action">
                                        <p>Completed</p>
                                        <input
                                          className="checkbox"
                                          type="checkbox"
                                          checked={items?.is_completed}
                                        />
                                      </div>
                                      <div className="action">
                                        <BsEyeSlash
                                          className="download-icon"
                                          style={{
                                            display: items?.is_hidden
                                              ? "flex"
                                              : "none",
                                          }}
                                          onClick={() => {
                                            handleOpenUnHideModal();
                                            setDocID(items?.id);
                                          }}
                                        />
                                        <BsEye
                                          className="download-icon"
                                          style={{
                                            display: items?.is_hidden
                                              ? "none"
                                              : "flex",
                                          }}
                                          onClick={() => {
                                            handleOpenHideModal();
                                            setDocID(items?.id);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="headline">
                                    <div className="left-side">
                                      <img src={doc} alt="doc" />
                                      <h4
                                        style={{
                                          display: generalFields
                                            ? "flex"
                                            : "none",
                                        }}
                                      >
                                        {items?.type}{" "}
                                        {items?.law_number
                                          ? items?.law_number === "nan"
                                            ? "N/A"
                                            : items?.law_number
                                          : "N/A"}
                                        -{items?.year}
                                      </h4>
                                      <h4
                                        style={{
                                          display: agenciesFields
                                            ? "flex"
                                            : "none",
                                        }}
                                      >
                                        {items?.type}{" "}
                                        {items?.regulation_number
                                          ? items?.regulation_number
                                          : items?.law_number}
                                        -{items?.year}
                                      </h4>
                                      <h4
                                        style={{
                                          display: municipalFields
                                            ? "flex"
                                            : "none",
                                        }}
                                      >
                                        {items?.type}{" "}
                                        {items?.municipality_number
                                          ? items?.law_number === "nan"
                                            ? "N/A"
                                            : items?.municipality_number
                                          : "N/A"}
                                        -{items?.year}
                                      </h4>
                                    </div>
                                    <h4 className="center">
                                      {items?.status
                                        ? items?.status === "nan"
                                          ? "N/A"
                                          : items?.status
                                        : "N/A"}
                                    </h4>
                                    <h4>
                                      {items?.date
                                        ? items?.date === "nan"
                                          ? ""
                                          : items?.date
                                        : ""}
                                    </h4>
                                  </div>
                                  <h4
                                    className="category"
                                    style={{
                                      display: agenciesFields ? "flex" : "none",
                                    }}
                                  >
                                    {items?.agency
                                      ? items?.agency === "nan"
                                        ? ""
                                        : items?.agency
                                      : ""}
                                  </h4>
                                  <h4
                                    className="category"
                                    style={{
                                      display: municipalFields
                                        ? "flex"
                                        : "none",
                                    }}
                                  >
                                    {items?.municipality
                                      ? items?.municipality === "nan"
                                        ? ""
                                        : items?.municipality
                                      : ""}
                                  </h4>
                                  {/* {items?.name
                                    ? items?.name === "nan"
                                      ? ""
                                      : items?.name
                                    : ""} */}
                                    <HyperlinkParagraph 
                                     name={items?.name} 
                                     type={items?.type} 
                                     law_number={items?.law_number} 
                                     year={items?.year} 
                                     hyperLinkDocument={hyperLinkDocument}
                                     setHyperLinkDocument={setHyperLinkDocument}
                                     setLoadingButton={setLoadingButton}
                                    />
                                  <div
                                    className="action-icons"
                                    style={{
                                      display: isStaff ? "flex" : "none",
                                    }}
                                  >
                                  <button
                                    className="action-button-differ"
                                    style={{display: items?.thumbnail ? "none" : "flex"}}
                                    onClick={() =>
                                      handleThumbnail(
                                        items?.doc_id
                                      )
                                    }
                                  >
                                  {thumbnailLoading[items?.doc_id] ? <Loader width={100} /> : "Generate Thumbnail"}
                                  </button>
                                    <button
                                      className="action-button"
                                      onClick={() =>
                                        handleOpenGetTitleModal(
                                          items?.file_content
                                        )
                                      }
                                    >
                                      Get Title
                                    </button>
                                    <AiOutlineDelete
                                      className="delete-icon"
                                      onClick={() => {
                                        handleOpenDeleteModal();
                                        setDeleteID(items?.id);
                                      }}
                                    />
                                    <Link
                                      className="edit-icon"
                                      to={`/update-documents-en?id=${items.id}&link=/search-documents-en`}
                                    >
                                      <FiEdit />
                                    </Link>
                                  </div>
                                </div>
                                <input
                                  style={{ display: isStaff ? "flex" : "none" }}
                                  type="checkbox"
                                  className="select-checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(items.id)
                                  }
                                  checked={selectedCheckboxes.includes(
                                    items.id
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                  </>
                    )
                  }

                  {searchResult?.data?.length === 0 || isRefDocument === true ? (
                    <p></p>
                  ) : (
                    <PagingNation
                      onPageChange={handlePageChange}
                      pageCount={searchResult?.total_pages}
                    />
                  )}
                </div>
              )}
            </>
            )}
            </>
          )}
        </div>
      </div>
      <div className="col-md-3">
        {showDeleteModal && (
          <Modal
            title="Delete Document"
            content={
              <DeleteSearchDocument
                handleCloseDeleteModal={handleCloseDeleteModal}
                deleteID={deleteID}
                deleteLoading={deleteLoading}
                setDeleteLoading={setDeleteLoading}
              />
            }
            onClose={handleCloseDeleteModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {hideModal && (
          <Modal
            title="Hide Document"
            content={
              <HideDocument
                docID={docID}
                handleCloseHideModal={handleCloseHideModal}
                setIsHideLoading={setIsHideLoading}
              />
            }
            onClose={handleCloseHideModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {unHideModal && (
          <Modal
            title="Unhide Document"
            content={
              <UnHideDocument
                docID={docID}
                handleCloseUnHideModal={handleCloseUnHideModal}
                setIsHideLoading={setIsHideLoading}
              />
            }
            onClose={handleCloseUnHideModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {getTitleModal && (
          <Modal
            title="Get Title"
            content={
              <TextFile
                handleCloseGetTitleModal={handleCloseGetTitleModal}
                textFile={textFile}
              />
            }
            onClose={handleCloseGetTitleModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {editActionsModal && (
          <Modal
            title="Edit Actions"
            content={
              <EditActions
                handleCloseEditActionsModal={handleCloseEditActionsModal}
                selectedCheckboxes={selectedCheckboxes}
                setBulkLoading={setBulkLoading}
              />
            }
            onClose={handleCloseEditActionsModal}
          />
        )}
      </div>
      <div className="col-md-3">
        {notSelectedModal && (
          <Modal
            title="Edit Actions"
            content={"Please Select at least one Document."}
            onClose={handleCloseNotSelectedModal}
          />
        )}
      </div>
      <div>
        {/* <div style={{ display: token ? "block" : "none" }}>
          <UserProfile />
        </div> */}
        <Footer />
      </div>
    </div>
  );
};

export default SearchDocuments;
