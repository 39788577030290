import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { signup } from '../../../../../apis/signup';
import { toast } from 'react-toastify';
interface UserFormProps {
  handleCloseCreateUserForm: any;
}
interface UserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  group: string;
  profession: any;
  motive: any;
}

const initialValues: UserFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  group: '',
  profession: "nene",
  motive: "none",
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
  group: Yup.string().required('Group is required'),
});


const CreateNewUser = ({ handleCloseCreateUserForm }: UserFormProps) => {
    const countries = ['Group 1', 'Group 2', 'Group 3', 'Group 4'];
    
const onSubmit = (values: UserFormValues) => {
  signup(values)
  .then(() => {
    toast.success("New User created succesfully");
    handleCloseCreateUserForm();
  })
  .catch((err) => {
    if (err) toast.error(err?.response?.data?.error);
  });
};
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className='user-form'>
          <div className="form-field">
            <label htmlFor="firstName">First Name</label>
            <Field className="input-field" type="text" id="firstName" name="firstName" />
            <ErrorMessage name="firstName" component="div" />
          </div>

          <div className="form-field">
            <label htmlFor="lastName">Last Name</label>
            <Field className="input-field" type="text" id="lastName" name="lastName" />
            <ErrorMessage name="lastName" component="div" />
          </div>

          <div className="form-field">
            <label htmlFor="email">Email</label>
            <Field className="input-field" type="email" id="email" name="email" />
            <ErrorMessage name="email" component="div" />
          </div>
          
          <div className="form-field">
            <label htmlFor="password">Password</label>
            <Field className="input-field" type="password" id="password" name="password" />
            <ErrorMessage name="password" component="div" />
          </div>

          <div className="form-field">
            <label htmlFor="group">Group</label>
            <Field className="input-field" as="select" id="group" name="group">
              <option value="" disabled>Select Group</option>
              {countries.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </Field>
            <ErrorMessage name="group" component="div" />
          </div>
            <button className='form-button' type="submit">Create</button>
        </Form>
      </Formik>
    </div>
  );
};

export default CreateNewUser;
