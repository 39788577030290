import "./AdminHeader.scss";
import { Link } from "react-router-dom";
import logoo from "../../../../../assests/images/newLogo.png";
import small from "../../../../../assests/images/small.png";
import Modal from "../../../../ReuseableComponents/Modal/Modal";
import NewUserSignupES from "../../SignUp/FormsES";
import React, { useState, useEffect } from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import AdminSettingSetupES from "../AdminSettingSetupES";
import AIAnalysisES from "../AIAnalysisES";
import Loader from "../../../../ReuseableComponents/Loader/Loader";
import BackupRecordsES from "../BackupRecords/BackupRecordsES";
import UserManagementES from "../UserManagement/UserManagementES";
import UsageES from "../Usage/UsageES";
export interface StaffProps {
  staff: any;
  insightDetails: any;
}

const AdminHeaderES = ({ staff, insightDetails }: StaffProps) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  let token = localStorage.getItem("token");
  let loginLink = "/admin-setting";

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  const componentList = [
    <AdminSettingSetupES />,
    <AIAnalysisES insightDetails={insightDetails} />,
    <BackupRecordsES />,
    <UserManagementES />,
    <UsageES />
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="admin-header">
      <div className="small-header">
        <div className="header-left">
          <div className="small">
            <img src={logoo} alt="small" />
          </div>
          <p className="small-haeder-text-diff">
            Portal oficial del Gobierno de Puerto Rico
          </p>
        </div>
        <div className="header-right">
          <Link className="navbar-link" to="/admin-setting">
            <h5 className="small-header-text-right">ES</h5>
          </Link>
          <div className="small-header-divider"></div>
          <Link className="navbar-link" to="/admin-setting-en">
            <h5 className="small-header-text-right">EN</h5>
          </Link>
          {token ? (
            <button className="log-out">
              <Link
                className="logout-link"
                to="/admin-setting"
                onClick={handleLogout}
              >
                Cerrar sesión
              </Link>
            </button>
          ) : (
            <button className="log-out" onClick={handleOpenModal}>
              <Link className="logout-link" to="/admin-setting">
                Acceso
              </Link>
            </button>
          )}
        </div>
      </div>
      {staff ? (
        <>
          {/* <div className="logoo">
            <Link to="/search-documents" className="back">
              {" "}
              <AiOutlineDoubleLeft className="back-icon" /> Atrás
            </Link>
            <Link className="logout-link" to="/admin-setting">
              <img src={logoo} alt="logo" />
            </Link>
          </div> */}
          <div className="new-logo">
            <Link to="/search-documents" className="back">
              {" "}
              <AiOutlineDoubleLeft className="back-icon" /> Atrás
            </Link>
        <div className="new-logo-container">
          <div className="logo-text">
            <p className="logo-heading">DEPARTAMENTO DE</p>
            <h3 className="logo-title">ESTADO</h3>
          </div>
          <div className="logo-divider"></div>
          <img className="new-logo-img" src={logoo} alt="logo" />
        </div>
      </div>
          <div className="documents-analysis">
            <button
              className={`documents-type-button ${page === 0 ? "active" : ""}`}
              onClick={() => setPage(0)}
            >
              Análisis de Documentos
            </button>
            <button
              className={`documents-type-button ${page === 1 ? "active" : ""}`}
              onClick={() => setPage(1)}
            >
              Análisis de IA
            </button>
            <button
              className={`documents-type-button ${page === 2 ? "active" : ""}`}
              onClick={() => setPage(2)}
            >
              Registros de Base de Datos
            </button>
            <button
              className={`documents-type-button ${page === 3 ? "active" : ""}`}
              onClick={() => setPage(3)}
            >
              Usuarios
            </button>
            <button
              className={`documents-type-button ${page === 4 ? "active" : ""}`}
              onClick={() => setPage(4)}
            >
              Uso
            </button>
          </div>
          <div className="Documents-detail-container">
            {componentList[page]}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "2rem 0 0 0",
          }}
        >
          {loading ? (
            <Loader width={300} />
          ) : (
            <>
              <h1 style={{ textAlign: "center", margin: "0" }}>
                Por Favor Iniciar Sesión
              </h1>
              <h3 style={{ textAlign: "center", margin: "5px 0" }}>
                Sólo el administrador del personal puede acceder a esta página.
              </h3>
            </>
          )}
        </div>
      )}
      <div className="col-md-3">
        {showModal && (
          <Modal
            title="Leyes de Puerto Rico"
            content={
              <NewUserSignupES
                handleCloseModal={handleCloseModal}
                loginLink={loginLink}
              />
            }
            onClose={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default AdminHeaderES;
