import React from "react";
import './TextFile.scss'

export interface TextProps {
  handleCloseGetTitleModal: any;
  textFile: any;
}

const TextFile: React.FC<TextProps> = ({
  handleCloseGetTitleModal,
  textFile,
}) => {

    const openPopup = () => {
        window.open(textFile, '_blank', 'width=600,height=400');
      }

  return (
    <div className="text-file-container">
      <p>{textFile}</p>
    {/* <button onClick={openPopup}>
      Open
    </button> */}
    </div>
  );
};

export default TextFile;
